import React, { useState, useEffect, Component } from "react";
import EnquirePopupFrom from '../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}

class Tab extends Component{
    constructor(props) {
        super(props);
        this.state = {
        isOpen: false,
        };
        this.toggleContent = this.toggleContent.bind(this);
    }

    toggleContent() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }
    render() {
        const { content } = this.props;
        const { isOpen } = this.state;
        return (
            <>
                <div className="tab-content">
                    <div  className={`Tcontainer ${isOpen ? 'open' : ''}`}>{content}</div>
                </div>
                {/* <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div>  */}
            </>
        );
    }
}


export default function OSParntTabs() {
    const [isAccordion, setIsAccordion] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggleAccordion = () => {
        setIsAccordion(window.innerWidth < 768);
    };
    const toggleTab = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        toggleAccordion();
        window.addEventListener("resize", toggleAccordion);
        return () => {
            window.removeEventListener("resize", toggleAccordion);
        };
    }, []);



  function ChallengesList(){
    return (
        <>
        <div className="ContsLists challengList">
            <ul>
                <li>
                    <h5>Digital Distractions</h5>
                    <p>Parents Battle Internet's Grip on Children Seeking Extra Information. Navigating Tech Temptations for Balanced Learning is a hard move for every parent.</p>
                </li>
                <li>
                    <h5>Guarding Kids Online</h5>
                    <p>Parents Confront Unwanted Internet Content Impacting Children's Safety. Navigating Digital Waters for Secure Upbringing is a persistent challenge. </p>
                </li>
                <li>
                    <h5>Fleeting Focus</h5>
                    <p>Parents Grapple with Children's Short Attention Span. The need for Nurturing Concentration in a Distracted World for Effective Learning is on continuous demand. </p>
                </li>
                <li>
                    <h5>Missing Progress Updates</h5>
                    <p>Parents Struggle with Limited Insight into Their Wards' Development.</p>
                </li>
                <li>
                    <h5>Missed School Updates</h5>
                    <p>Parents Navigate Lack of Information about Their Ward's School Journey.</p>
                </li>
                
            </ul>
        </div>
        </>
      );
  }
  function SolutionsList(){
    return (
        <div className="ContsLists solutionList">
            <ul>
                <li>
                    <h5>Digital Dependence</h5>
                    <p>KATBOOK offers a comprehensive content with identified keywords, concept and teaching videos, additional content, dictionary to new words, etc, that enable them to stay focused and the need for scouting additional content doesn’t arise, relieving parental stress.</p>
                </li>
                <li>
                    <h5>No More Inappropriate Exposure </h5>
                    <p>As KATBOOK offers additional content information both in text and video formats, the necessity for internet browsing doesn’t arise. This eliminates the fear of parents from exposure to undesired content.</p>
                </li>
                <li>
                    <h5>Enhanced Focus </h5>
                    <p>Availability of clarifications throughout the year, features like highlighting, book marking, taking notes etc, enhances the student to grasp the lesson anytime. This relieves parents to a great extent.</p>
                </li>
                <li>
                    <h5>Progress Updates </h5>
                    <p>All information about the child’s progress can be received through KATBOOK’s self assessment reports.</p>
                </li>
                <li>
                    <h5>School Updates </h5>
                    <p>Events & announcements of the school are readily available via KSMACC to the parents at their fingertips.</p>
                </li>
   
            </ul>
        </div>
      );
  }

  const tabs = [
    { title: "Challenges", content: (<ChallengesList/>), },
    { title: "Solutions", content: (<SolutionsList/>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);


  
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>       
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
