import React, { useState, useEffect, Component } from "react";
import EnquirePopupFrom from '../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}

class Tab extends Component{
    constructor(props) {
        super(props);
        this.state = {
        isOpen: false,
        };
        this.toggleContent = this.toggleContent.bind(this);
    }

    toggleContent() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }
    render() {
        const { content } = this.props;
        const { isOpen } = this.state;
        return (
            <>
                <div className="tab-content">
                    <div  className={`Tcontainer ${isOpen ? 'open' : ''}`}>{content}</div>
                </div>
                <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div> 
            </>
        );
    }
}


export default function OurSolutionsTabs() {
    const [isAccordion, setIsAccordion] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggleAccordion = () => {
        setIsAccordion(window.innerWidth < 768);
    };
    const toggleTab = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        toggleAccordion();
        window.addEventListener("resize", toggleAccordion);
        return () => {
            window.removeEventListener("resize", toggleAccordion);
        };
    }, []);



  function ChallengesList(){
    return (
        <>
        <div className="ContsLists challengList">
            <ul>
                <li>
                    <h5>Huge load of Books </h5>
                    <p>Navigating a vast sea of books poses a formidable challenge for today's students.</p>
                </li>
                <li>
                    <h5>Transition Trouble</h5>
                    <p>Adapting from Traditional Classrooms to online learning poses a contemporary student challenge.</p>
                </li>
                <li>
                    <h5>Uncertain Horizons</h5>
                    <p>The Modern Student's Challenge of anticipating the upcoming curriculum and course content.</p>
                </li>
                <li>
                    <h5>Silent Struggle</h5>
                    <p>Modern Students Grapple with the challenge of raising doubts and questions during virtual classes.</p>
                </li>
                <li>
                    <h5>Fading Echoes</h5>
                    <p>Recollection Challenge as students strive to recall In-Class clarifications during home revisions.</p>
                </li>
                <li>
                    <h5>After-Hours Helplessness</h5>
                    <p>The Modern Student's Challenge of accessing teacher clarifications beyond school hours, weekends, and before exams.</p>
                </li>
                <li>
                    <h5>Falling Behind</h5>
                    <p>Students struggle to keep pace with ongoing classes following absences due to illness or reasons.</p>
                </li>
                <li>
                    <h5>Waiting to Measure Up</h5>
                    <p>Students' challenge in self-assessment and reliance on school evaluations for feedback.</p>
                </li>
                <li>
                    <h5>Fleeting Focus</h5>
                    <p>Coping with the challenge of short attention spans in learning.</p>
                </li>
                <li>
                    <h5>Constant Interruptions</h5>
                    <p>Overcoming the challenge of frequent disruptions during learning sessions.</p>
                </li>
                <li>
                    <h5>Mounting Curriculum Burden</h5>
                    <p>Navigating the challenge of ever-increasing academic workloads.</p>
                </li>
                <li>
                    <h5>Limited Classroom Learning</h5>
                    <p>Confronting the challenge of inadequate In-Person educational experiences.</p>
                </li>
                <li>
                    <h5>Digital Distractions</h5>
                    <p>Battling the challenge of straying from focus topics during online searches.</p>
                </li>
                <li>
                    <h5>Online Exposure Risks</h5>
                    <p>Addressing the challenge of unexpected undesirable content encounters on the internet.</p>
                </li>
                <li>
                    <h5>Aspirational Disconnect</h5>
                    <p>Tackling the challenge of teaching quality and content falling short of student aspirations.</p>
                </li>
                <li>
                    <h5>Unlocking Potential</h5>
                    <p>Addressing the challenge of insufficient dynamic customized experiences for ambitious students.</p>
                </li>
            </ul>
        </div>
        </>
      );
  }
  function SolutionsList(){
    return (
        <div className="ContsLists solutionList">
            <ul>
                <li>
                    <h5>Learning Unleashed </h5>
                    <p>UEDUCATE's Solution offers Compact Online, Offline, and Hybrid Learning through KATBOOK with Web and Android/iOS Compatibility.</p>
                </li>
                <li>
                    <h5>Seamless Transition</h5>
                    <p>UEDUCATE's Solution Eases the Shift from Traditional Classrooms to Online Learning, Providing Supportive Resources from KATBOOK with content and concept videos.</p>
                </li>
                <li>
                    <h5>Empowering Anticipation</h5>
                    <p>UEDUCATE's Digital Timetable Delivers Class-wise, Subject-wise, and Chapter-wise Sliced Content, additional content for identified Keywords, Concept and Teaching Videos enhancing the Preparedness for Upcoming Classes.</p>
                </li>
                <li>
                    <h5>Continuous Clarity</h5>
                    <p>Round-the-clock teacher support for student questions, ensuring year-long access to clarifications.</p>
                </li>
                <li>
                    <h5>Accessible Learning</h5>
                    <p>On-demand access to content, concept & teaching videos, plus clarifications on KATBOOK, ensuring lasting understanding for students.</p>
                </li>
                <li>
                    <h5>Teacher Assistance Anytime</h5>
                    <p>The 'Clarify’ feature ensures continuous help for students on KATBOOK after-school hours, on weekends, and before-exams.</p>
                </li>
                <li>
                    <h5>Illness Recovery Support</h5>
                    <p>Catch up on missed classes via digital timetable, guiding students to relevant chapters for revision on KATBOOK.</p>
                </li>
                <li>
                    <h5>Chapter Checkpoint</h5>
                    <p>Validate understanding with self-assessment sections for each chapter, ensuring step-by-step progress on KATBOOK.</p>
                </li>
                <li>
                    <h5>Flexible Learning</h5>
                    <p>Mitigate short attention spans by accessing content anytime on any device, limited to 2 devices per student login on KATBOOK.</p>
                </li>
                <li>
                    <h5>Empowering Focus</h5>
                    <p>Surpass constant disruptions with on-demand clarifications and content access across devices, fostering learning at KATBOOK.</p>
                </li>
                <li>
                    <h5>Conquer academic overload </h5>
                    <p>Tackle academic overload through systematic learning. Amplify teacher mentoring via segmented lessons for focused preparation.</p>
                </li>
                <li>
                    <h5>Embrace Versatile Learning </h5>
                    <p>With KATBOOK classroom absence is defeated with online, offline & hybrid modes, revolutionizing education accessibility.</p>
                </li>
                <li>
                    <h5>Empowering Concentration</h5>
                    <p>Shield from digital diversions via Identified Keywords, additional contents, teaching & concept videos, ensuring immersive learning.</p>
                </li>
                <li>
                    <h5>Embrace Safe Learning</h5>
                    <p>Vanquish online exposure concerns. Abundant additional content, concept, and teaching videos ensure a secure educational journey.</p>
                </li>
                <li>
                    <h5>Elevate Teaching Quality</h5>
                    <p>The teacher’s preparation time is optimized enriching education through focused session planning with KATBOOK.</p>
                </li>
                <li>
                    <h5>Aspirant’s choice </h5>
                    <p>Dynamic Customized learning experience is achieved through the bouquet of KATBOOK, KAMPUS & KSMACC. </p>
                </li>
            </ul>
        </div>
      );
  }

  const tabs = [
    { title: "Challenges", content: (<ChallengesList/>), },
    { title: "Solutions", content: (<SolutionsList/>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);


  
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>       
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
