import React, { useState, useEffect } from "react";

import { Col,  Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}
function Tab({  content }) {
    return (
      <div className="tab-content">
        <>{content}</>
      </div>
    );
  }
export default function KamaccTabs() {
  const [isAccordion, setIsAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsAccordion(window.innerWidth < 768);
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    toggleAccordion();
    window.addEventListener("resize", toggleAccordion);
    return () => {
      window.removeEventListener("resize", toggleAccordion);
    };
  }, []);

  const tabs = [
    { title: "Students", content: ( <Row>
      <Col ms="6">
          <div className='WCL-Img'>
              <img src={require ('../../../asstes/images/katbook-Student-img.png')} alt="Benefits for Teachers"/>
          </div>
      </Col>
      <Col ms="6">
          <div className='WCL-Cont'>
              <h4>Benefits for Students</h4>
              <h5>Unleash Student Potential with KSMACC</h5>
              <p>KSMACC offers a myriad of benefits for students, transforming their learning experience. With seamless communication channels and access to diverse resources, students can collaborate efficiently and gain a deeper understanding of subjects. The platform's interactive features of KALL & KATCHUP modules personalize learning paths, and empower students to excel academically and grow holistically.</p>
              <p className='exprbtn'><Link to='/' className=''>enquire now</Link></p>
          </div>
      </Col>
  </Row>), },
    { title: "Teachers", content: (<Row>
      <Col ms="6">
          <div className='WCL-Img'>
              <img src={require ('../../../asstes/images/WCKB-StudImg.png')} alt="Benefits for Teachers"/>
          </div>
      </Col>
      <Col ms="6">
          <div className='WCL-Cont'>
              <h4>Benefits for Teachers</h4>
              <h5>Unlock Teacher Potential and Elevate the Impact</h5>
              <p>KSMACC empowers teachers with a host of benefits, enriching their teaching journey. The platform facilitates seamless communication with students, parents, and management effortlessly with individual role-based IDs. With access to a comprehensive student directory, teachers can tailor their approach to individual students, foster engagement, and inspire meaningful learning experiences, leading to academic excellence.</p>
              <p className='exprbtn'><Link to='/' className=''>enquire now</Link></p>
          </div>
      </Col>
  </Row>), },
    { title: "Parents", content: (<Row>
      <Col ms="6">
          <div className='WCL-Img'>
              <img src={require ('../../../asstes/images/katbook-Parents-img.png')} alt="Benefits for Teachers"/>
          </div>
      </Col>
      <Col ms="6">
          <div className='WCL-Cont'>
              <h4>Benefits for Parents</h4>
              <h5>Nurturing Growth by Empowering Parents with KSMACC</h5>
              <p>KSMACC brings a wealth of benefits to parents, fostering a strong partnership in their child's education journey. With real-time updates on their child's progress and communication with teachers, parents stay informed and involved. The platform's secure and closed communication channels enable seamless interactions, ensuring parents actively participate in their child's academic success.</p>
              <p className='exprbtn'><Link to='/' className=''>enquire now</Link></p>
          </div>
      </Col>
  </Row>), },
    { title: "Institutions", content: (<Row>
      <Col ms="6">
          <div className='WCL-Img'>
              <img src={require ('../../../asstes/images/katbook-Institution-img.png')} alt="Benefits for Teachers"/>
          </div>
      </Col>
      <Col ms="6">
          <div className='WCL-Cont'>
              <h4>Benefits for Institutions</h4>
              <h5>Transforming Institutions, Empowering Excellence</h5>
              <p>KSMACC delivers a plethora of benefits to institutions, revolutionizing their academic ecosystem. With Closed Intra-Institutional communication, role-based access, and secure data management, institutions enhance administrative efficiency. KSMACC’s exclusive patented features of Modules - KALL & KATCHUP enable exhaustive communication among the stakeholders. </p>
              <p className='exprbtn'><Link to='/' className=''>enquire now</Link></p>
          </div>
      </Col>
  </Row>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>         
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} /></>
  );
}
