import React, { useState } from "react";
import axios from "axios";
import { Col, Row, Form, Button } from "react-bootstrap";
import "./contact.css";


const ContactusForm = () => {
    const handleMobileKeyPress = e => {
      const mobileValue = e.target.value;
      if (mobileValue.length >= 10) {
        e.preventDefault();
      }
    };
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        whoareyou: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    };

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.name) {
            validationErrors.name = "Name is required";
        }

        if (!formData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = "Invalid email format";
        }

        if (!formData.mobile) {
            validationErrors.mobile = "Mobile number is required";
        } 

        if (!formData.whoareyou) {
            validationErrors.whoareyou = "Select an option";
        }

        return validationErrors;
    };
    
    const handleSubmit = async e => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);  
            try {
                await axios.post(`https://www.ueducate.in:3001/send-email`, {
                    name: formData.name,
                    email: formData.email,
                    mobile: formData.mobile,
                    whoareyou: formData.whoareyou,
                    message: formData.message,
                    subject: "Contact Us Form",
                });
                setFormData({
                    name: "",
                    email: "",
                    mobile: "",
                    whoareyou: "",
                    message: "",
                });
                alert("Email sent successfully");
            } catch (error) {
                alert("An error occurred while sending the email");
            }
            setIsLoading(false);
        } else {
            setErrors(validationErrors);
        }
    };
 return (
  <Form noValidate onSubmit={handleSubmit} className="ExpertForm">
   <Row>
    <Col md={6}>
     <Form.Group className="mb-3" controlId="formBasicFName">
      <input
       name="name"
       id="name"
       type="text"
       className="textfield__input"
       value={formData.name}
       required
       onChange={handleChange}
      />
      <label for="name" className="textfield__label textfield__label--required">
       {" "}
       Name
      </label>
      {errors.name && <p className='field-error'>{errors.name}</p>}
     </Form.Group>
     <Form.Group className="mb-3" controlId="formBasicEmail">
      <input
       name="email"
       id="email"
       type="email"
       className="textfield__input"
       value={formData.email}
       required
       onChange={handleChange}
      />
      <label
       for="email"
       className="textfield__label textfield__label--required">
       {" "}
       Email
      </label>
      {errors.email && <p className='field-error'>{errors.email}</p>}
     </Form.Group>
    </Col>
    <Col md={6}>
     <Form.Group className="mb-3" controlId="formBasicContactNumber">
      <input
       name="mobile"
       id="PhoneNumber"
       type="tel"
       className="textfield__input"
       value={formData.mobile}
       required
       onChange={handleChange}
       onKeyPress={handleMobileKeyPress}
      />
      <label
       for="PhoneNumber"
       className="textfield__label textfield__label--required">
       {" "}
       Phone Number
      </label>
      {errors.mobile && <p className='field-error'>{errors.mobile}</p>}
     </Form.Group>
     <Form.Group className="mb-3 posrel" controlId="formBasicPhone">
        <Form.Select name="whoareyou" aria-label="Default select example" value={formData.whoareyou} onChange={handleChange} required>
            <option value="">Select Who You Are*</option>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
            <option value="Institution">Institution</option>
            <option value="Academy">Academy</option>
            <option value="University">University</option>
            <option value="Corporate">Corporate</option>
            <option value="Publisher">Publisher</option>
            <option value="Content Creator">Content Creator</option>
        </Form.Select> 
            {errors.whoareyou && <p className='field-error'>{errors.whoareyou}</p>}
    </Form.Group>
    </Col>
    <Col md={12}>
     <Form.Group className="mb-3" controlId="formBasicCName">
     <textarea rows="3" name="message" placeholder="Message" id="formBasicCName" class="form-control" value={formData.message} onChange={handleChange}></textarea>
     </Form.Group>
    </Col>
   </Row>
   <Button className="primary submitBtn" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</Button>
  </Form>
 );
};

export default ContactusForm;
