import React, { useState, useEffect } from "react";

import { Col,  Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}
function Tab({  content }) {
    return (
      <div className="tab-content">
        <>{content}</>
      </div>
    );
  }
export default function KampusTabs() {
  const [isAccordion, setIsAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsAccordion(window.innerWidth < 768);
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    toggleAccordion();
    window.addEventListener("resize", toggleAccordion);
    return () => {
      window.removeEventListener("resize", toggleAccordion);
    };
  }, []);

  const tabs = [
    { title: "Students", content: ( <Row>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Student-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Students</h4>
                <h5>KAMPUS: Your Gateway to Comprehensive<br/> E-Learning</h5>
                <p>A Comprehensive E-Learning Module with user-friendly student dashboards, seamlessly managing attendance, assignments, online exams, and results. Effortless Virtual Learning with integrated dynamic timetables for seamless class access. Focused Learning Made Easy - Instantly access relevant subject sections in Kampus sessions.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
    </Row>), },
    { title: "Teachers", content: (<Row>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/WCKB-StudImg.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Teachers</h4>
                <h5>Elevate Learning, Empower Teachers, and <br/>Engage Students.</h5>
                <p>With KAMPUS, lesson preparation time is minimized, while additional data enriches content over time. Complete knowledge transfer is ensured, recorded, and accessed by students, keeping the teacher's focus and attention. Real-time reports provide students with insights into their lesson progress and interaction levels, optimizing the learning experience.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
    </Row>), },
    { title: "Parents", content: (<Row>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Parents-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Parents</h4>
                <h5>Connecting and Celebrating Safely.</h5>
                <p>Secure Communication for All - Connect Parents, Teachers, Staff, and Students Safely. Stay Informed, Engage, and Celebrate with our Dynamic Virtual Notice Board, keeping you updated on exciting school events and announcements.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
    </Row>), },
    { title: "Institutions", content: (<Row>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Institution-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Institutions</h4>
                <h5>Seamless Teaching, Management, and Collaboration<br/> for Transformative Education.</h5>
                <p>KAMPUS provides institutions with versatile teaching options, seamless management, and a 24*7 digital environment. Its real-time planning, tracking, and easy assignment transfers streamline administrative tasks, enabling enhanced efficiency and improved collaboration for transformative education.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
    </Row>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>         
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
