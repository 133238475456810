import React, { useState } from "react";
import axios from "axios";
import { Form, Button } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';

const TalktoExpert = () => {
    const handleMobileKeyPress = e => {
      const mobileValue = e.target.value;
      if (mobileValue.length >= 10) {
        e.preventDefault();
      }
    };
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        companyname: "",
        message: "",
        agreed: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    };

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.firstname) {
            validationErrors.firstname = "First Name is required";
        }

        if (!formData.lastname) {
            validationErrors.lastname = "Last Name is required";
        }

        if (!formData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = "Invalid email format";
        }

        if (!formData.mobile) {
            validationErrors.mobile = "Mobile number is required";
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            validationErrors.mobile = "Invalid mobile number";
        }

        if (!formData.companyname) {
            validationErrors.companyname = "Company Name is required";
        }

        if (!formData.agreed) {
            validationErrors.agreed = "Please Agree to Policy";
        }

        return validationErrors;
    };
    
    const handleSubmit = async e => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);  
            try {
                await axios.post(`https://www.ueducate.in:3001/send-email`, {
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    email: formData.email,
                    mobile: formData.mobile,
                    companyname: formData.companyname,
                    message: formData.message,
                    agreed: formData.agreed,
                    subject: "Talk to an Expert Form",
                });
                setFormData({
                    firstname: "",
                    lastname: "",
                    email: "",
                    mobile: "",
                    companyname: "",
                    message: "",
                    agreed: "",
                });
                alert("Email sent successfully");
            } catch (error) {
                alert("An error occurred while sending the email");
            }
            setIsLoading(false);
        } else {
            setErrors(validationErrors);
        }
    };
  return (
    <Form noValidate onSubmit={handleSubmit} className='ExpertForm'>
        <Form.Group className="mb-3" controlId="formBasicFName">
            <Form.Control type="text" name="firstname" placeholder="First Name*" value={formData.firstname} onChange={handleChange} required/>
            {errors.firstname && <p className='field-error'>{errors.firstname}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLName">
            <Form.Control type="text" name="lastname" placeholder="Last Name*" value={formData.lastname} onChange={handleChange} required/>
            {errors.lastname && <p className='field-error'>{errors.lastname}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="email" name="email" placeholder="Email ID*" value={formData.email} onChange={handleChange} required/>
            {errors.email && <p className='field-error'>{errors.email}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicContactNumber">
            <Form.Control type="tel" name="mobile" placeholder="Contact Number*" value={formData.mobile} onChange={handleChange} onKeyPress={handleMobileKeyPress} required/>
            {errors.mobile && <p className='field-error'>{errors.mobile}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCName">
            <Form.Control type="text" name="companyname" placeholder="Company Name*" value={formData.companyname} onChange={handleChange} required/>
            {errors.companyname && <p className='field-error'>{errors.companyname}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCName">
            <Form.Control as="textarea" name="message" rows={2}  placeholder="Message" value={formData.message} onChange={handleChange}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" name="agreed" label="I agree to terms of use and privacy policy" value="Yes" onChange={handleChange} required/>
            {errors.agreed && <p className='field-error'>{errors.agreed}</p>}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</Button>
    </Form>
  )
}



export default TalktoExpert