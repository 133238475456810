import React from 'react';
import { Helmet } from 'react-helmet';

function BookaDemo() {
   
  return (
    <div>
      <Helmet>
        <script>
          {`
            if (window.Calendly) {
              window.Calendly.initInlineWidget({
                url: 'https://calendly.com/ueducate/book-a-demo',
                parentElement: document.getElementById('calendly-container'),
              });
            }
          `}
        </script>
      </Helmet>
      <div id="calendly-container"/>
      </div>
  );
}

export default BookaDemo;
