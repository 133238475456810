import React, { useState, useEffect } from "react";

import { Col,  Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}
function Tab({  content }) {
    return (
      <div className="tab-content">
        <>{content}</>
      </div>
    );
  }
export default function KampusTabs() {
  const [isAccordion, setIsAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsAccordion(window.innerWidth < 768);
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    toggleAccordion();
    window.addEventListener("resize", toggleAccordion);
    return () => {
      window.removeEventListener("resize", toggleAccordion);
    };
  }, []);

  const tabs = [
    { title: "Students", content: ( <Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Students</h4>
                <h5>Evolving from your past by learning<br/> and growing.</h5>
                <p>Being a student who is curated to learn and needs a place to study, upgrade, and evolve oneself, USTUDY offers an experience with a personalized learning expedition where your unique needs take center stage. With an expansive archive of curated resources, you'll delve into expert-driven content with Interactive learning modules and Real-time progress tracking. you will be a part of the global learning community that transcends borders, forging connections with peers worldwide exploration. Your learning journey is personalized, and your next step to success is within reach.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Student-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    { title: "Corporates", content: (<Row>
      <Col ms="6">
          <div className='WCL-Cont'>
              <h4>Benefits for Corporates</h4>
              <h5>Empower your workforce to drive <br/>sustainable growth.</h5>
              <p>In today's corporate landscape, fostering a skilled and engaged workforce is essential for sustained growth. USTUDY steps in as your partner in achieving just that, with a range of finely tuned training solutions designed to elevate your team's capabilities. By providing Streamlined Employee Training with Targeted Courses with customized Education for Specific Job Roles and Responsibilities. Embracing Flexibility with On-Campus, Off-Campus, or Hybrid Learning Models with maintaining Track Your Learning Journey and Skill Development. Our solutions uplift your team to grasp new challenges and opportunities, driving your organization toward a future of innovation and success.</p>
              <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
          </div>
      </Col>
      <Col ms="6">
          <div className='WCL-Img'>
              <img src={require ('../../../asstes/images/ustudy-Corporates-image.png')} alt="Benefits for Teachers"/>
          </div>
      </Col>
  </Row>), },
    { title: "Institutions", content: (<Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Educational Institutions</h4>
                <h5>Crafting a Path that Aligns with Your<br/> Role and Ambitions.</h5>
                <p>Imagine a platform that resonates with your dream for professional convenience. USTUDY, a comprehensive platform designed to enhance every aspect of academic excellence, Seamlessly streamline course planning, scheduling, and data management while offering engaging online learning experiences. It facilitates efficient communication, customization, and resource control, ensuring a tailored and effective educational journey. Choosing USTUDY is empowering your institution with advanced solutions that drive efficiency, engagement, and excellence in education.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Institution-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    
    // { title: "Institutions", content: (<Row>
    //     <Col ms="6">
    //         <div className='WCL-Img'>
    //             <img src={require ('../../../asstes/images/katbook-Institution-img.png')} alt="Benefits for Teachers"/>
    //         </div>
    //     </Col>
    //     <Col ms="6">
    //         <div className='WCL-Cont'>
    //             <h4>Benefits for Institutions</h4>
    //             <h5>Seamless Teaching, Management, and Collaboration<br/> for Transformative Education.</h5>
    //             <p>KAMPUS provides institutions with versatile teaching options, seamless management, and a 24*7 digital environment. Its real-time planning, tracking, and easy assignment transfers streamline administrative tasks, enabling enhanced efficiency and improved collaboration for transformative education.</p>
    //             <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
    //         </div>
    //     </Col>
    // </Row>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>         
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
