import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import EnquirePopupFrom from '../EnquirePopupFrom'

const Enquirenow = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className='EnowSec'>
        <div className='EnowSecBg AuoSecBg'><img src={require ('../../asstes/images/FooteEnqNowBg.jpg')} alt="Enquire Now"/></div>
        <div className='EnowSecCont'>
            <Container>
                <div className='EnowInCont'>
                    <h6 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="0">Enquire Now</h6>
                    <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Ignite Limitless <br/>Learning</h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Embark on a journey of boundless <br/>knowledge and endless possibilities with <br/>UEDUCATE's transformative learning <br/>platform.</p>
                    <p class="btnpara" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300"><Link to="#"  onClick={() => setModalShow(true)}>enquire now</Link></p>
               </div>
            </Container>
            <div className='EnowInLink'  data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
              <h6>EXPERIENCE katbookstore APP ON MOBILE</h6>              
              <Link to='https://www.katbookstore.com/' target='_blank'><img src={require('../../asstes/images/digital-katebook-img.png')} alt="digital-katebook-img"/></Link>
              <ul>
                <li><Link to=''><img src={require ('../../asstes/images/GPlayImg.webp')} alt="Google Play"/></Link></li>
                <li><Link to=''><img src={require ('../../asstes/images/AppStoreImg.webp')} alt="App Store"/></Link></li>
              </ul>
            </div>
        </div>
    </div>
    <EnquirePopupFrom
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                </>
  )
}

export default Enquirenow