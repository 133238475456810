import React, { useState, useEffect, Component } from "react";
import EnquirePopupFrom from '../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}

class Tab extends Component{
    constructor(props) {
        super(props);
        this.state = {
        isOpen: false,
        };
        this.toggleContent = this.toggleContent.bind(this);
    }

    toggleContent() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }
    render() {
        const { content } = this.props;
        const { isOpen } = this.state;
        return (
            <>
                <div className="tab-content">
                    <div  className={`Tcontainer ${isOpen ? 'open' : ''}`}>{content}</div>
                </div>
                <div className="alllessbtn">
                    <button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div> 
            </>
        );
    }
}


export default function OSTeachTabs() {
    const [isAccordion, setIsAccordion] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggleAccordion = () => {
        setIsAccordion(window.innerWidth < 768);
    };
    const toggleTab = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        toggleAccordion();
        window.addEventListener("resize", toggleAccordion);
        return () => {
            window.removeEventListener("resize", toggleAccordion);
        };
    }, []);



  function ChallengesList(){
    return (
        <>
        <div className="ContsLists challengList teacher">
            <ul>
                <li>
                    <h5>Coping with the expanding curriculum </h5>
                    <p>Teachers face the challenge of managing expanding curriculum demands, impacting depth and effective learning.</p>
                </li>
                <li>
                    <h5>360 degree teaching expectations</h5>
                    <p>Teachers grapple with meeting the demands of comprehensive teaching, satisfying both parents' and students' expectations for holistic education.</p>
                </li>
                <li>
                    <h5>Juggling Innovation and Preparation</h5>
                    <p>Modern teachers struggle to balance staying updated with subject advancements and dedicating ample time for quality class preparation.</p>
                </li>
                <li>
                    <h5>Time Strain on Mentorship</h5>
                    <p>Teachers find limited time for nurturing students' growth due to increasing demands on their schedules and lack of time.</p>
                </li>
                <li>
                    <h5>Board vs. Bond</h5>
                    <p>Teachers spend excessive time on boards, hindering student supervision and interaction, and affecting overall engagement and learning.</p>
                </li>
                <li>
                    <h5>Real-time Assessment Gap</h5>
                    <p>The inability to gauge students' comprehension instantly in class hampers timely feedback and adaptive teaching strategies.</p>
                </li>
                <li>
                    <h5>Balancing Doubts and Syllabus</h5>
                    <p>Time constraints to cover syllabus limit addressing all student doubts adequately, affecting comprehensive learning support.</p>
                </li>
                <li>
                    <h5>Repetitive Queries Challenge </h5>
                    <p>Addressing recurring student queries across different instances consumes time, impacting overall classroom efficiency and learning progression.</p>
                </li>
            </ul>
        </div>
        </>
      );
  }
  function SolutionsList(){
    return (
        <div className="ContsLists solutionList">
            <ul>
                <li>
                    <h5>Simplifying the Growing Curriculum</h5>
                    <p>Streamlined approach tackles expanding demands. Dynamic timetable, concept videos, self-assessments aid teachers in timely completion, ensuring comprehensive student learning.</p>
                </li>
                <li>
                    <h5>Meeting Holistic Teaching Demands</h5>
                    <p>KATBOOK empowers educators to fulfill 360-degree teaching expectations, bridging parent and student aspirations for comprehensive education.</p>
                </li>
                <li>
                    <h5>Empowering Modern Educators</h5>
                    <p>UEDUCATE’S KATBOOK enables seamless balance between staying updated on subject advancements and ensuring quality class preparation.</p>
                </li>
                <li>
                    <h5>Revitalizing Mentorship Amid Time Constraints</h5>
                    <p>KATBOOK offers solutions to teachers, mitigating time pressure and allowing focused student growth.</p>
                </li>
                <li>
                    <h5>From Boards to Bonds</h5>
                    <p>KATBOOK reshapes teacher priorities, fostering student interaction and engagement for enriched learning experiences.</p>
                </li>
                <li>
                    <h5>Bridging Real-Time Assessment Gap</h5>
                    <p>KATBOOK’S chapter-wise self-assessment sessions enable instant comprehension evaluation, enabling timely feedback and adaptive teaching strategies.</p>
                </li>
                <li>
                    <h5>Harmonizing Doubt Resolution and Syllabus</h5>
                    <p>KATBOOK’S 24/7 clarification option addresses time constraints, ensuring continual doubt resolution and comprehensive learning support throughout the year.</p>
                </li>
                <li>
                    <h5>Easing Repetitive Queries</h5>
                    <p>KATBOOK’S persistent clarification system tackles recurring student questions, optimizing classroom efficiency and promoting seamless learning progression at the pace of individual students. </p>
                </li>
            </ul>
        </div>
      );
  }

  const tabs = [
    { title: "Challenges", content: (<ChallengesList/>), },
    { title: "Solutions", content: (<SolutionsList/>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);


  
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>       
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
