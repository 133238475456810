import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Col,  Row, } from 'react-bootstrap';

class Kamaccpowerlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleContent() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;

    return (
        <>
        <div className={`Tcontainer ${isOpen ? 'open' : ''}`}>        
            <Row>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Seamless Intra-Institution Communication</Accordion.Header>
                            <Accordion.Body>
                                Completely CLOSED- Intra-Institution communication for students, teachers, parents, principal and other staff with exclusive ID access.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Empowering Executives with Open Communication with KPOST</Accordion.Header>
                            <Accordion.Body>
                                KPOST, supports executives to connect beyond the intra-institutional boundaries with vendors and service providers.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Admin Empowerment</Accordion.Header>
                            <Accordion.Body>
                                The administrator of the institution hold the complete control over the access for the communication channel.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Uncompromising Security</Accordion.Header>
                            <Accordion.Body>
                                End to end encryption for data protection.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Versatile Access</Accordion.Header>
                            <Accordion.Body>
                                Device-Independent learning on Web, Android, and iOS.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Katchup and Kall</Accordion.Header>
                            <Accordion.Body>
                                Innovative Patented Features for Enhanced Communication.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Effective Staff Communication</Accordion.Header>
                            <Accordion.Body>
                                Role-Based Permanent Channels for the Institution’s staff and the same can be assigned to or transferred from one person to another.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Class-Centric Communication</Accordion.Header>
                            <Accordion.Body>
                                Teachers will be able to communicate through exclusive channels class and section-wise. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Integrated dashboard with filter features</Accordion.Header>
                            <Accordion.Body>
                                Experience the integrated KSMACC dashboards with date-wise and module-wise filters.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Enhanced Control and Flexibility</Accordion.Header>
                            <Accordion.Body>
                                Explore Provision for Backup Admin and More - Empower Your KSMACC Experience.
                            </Accordion.Body>
                        </Accordion.Item>
                    
                    </Accordion>
                </Col>
            </Row>
        </div>
        <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div>
        </>
    );
  }
}

export default Kamaccpowerlist;
