import React, { useState, useEffect, Component } from "react";
import EnquirePopupFrom from '../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}

class Tab extends Component{
    constructor(props) {
        super(props);
        this.state = {
        isOpen: false,
        };
        this.toggleContent = this.toggleContent.bind(this);
    }

    toggleContent() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }
    render() {
        const { content } = this.props;
        const { isOpen } = this.state;
        return (
            <>
                <div className="tab-content">
                    <div  className={`Tcontainer ${isOpen ? 'open' : ''}`}>{content}</div>
                </div>
                {/* <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div>  */}
            </>
        );
    }
}


export default function OSInstituTabs() {
    const [isAccordion, setIsAccordion] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggleAccordion = () => {
        setIsAccordion(window.innerWidth < 768);
    };
    const toggleTab = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        toggleAccordion();
        window.addEventListener("resize", toggleAccordion);
        return () => {
            window.removeEventListener("resize", toggleAccordion);
        };
    }, []);



  function ChallengesList(){
    return (
        <>
        <div className="ContsLists challengList">
            <ul>
                <li>
                    <h5>Balancing two modes </h5>
                    <p>Navigating between on-campus and off-campus modes disrupts consistency, impacting teaching approach and student engagement.</p>
                </li>
                <li>
                    <h5>Diverse Parental Choices </h5>
                    <p>Catering to diverse parental preferences for sending their children to school under various circumstances poses logistical and planning hurdles.</p>
                </li>
                <li>
                    <h5>Seeking Quality Educators </h5>
                    <p>High demand for quality teachers surpasses the limited supply, impacting educational standards and learning outcomes.</p>
                </li>
                <li>
                    <h5>Infrastructure Gap in Tier 2-3 cities</h5>
                    <p>Limited infrastructure and support systems in institutions in tier 2-3 cities hinder holistic educational development.</p>
                </li>
                <li>
                    <h5>Attendance Troubles </h5>
                    <p>Low student attendance impacts engagement, participation, and overall learning environment in educational settings.</p>
                </li>
                <li>
                    <h5>Building Resilient Systems</h5>
                    <p>Creating a robust educational system to withstand disruptions and adapt to changes remains imperative.</p>
                </li>
                <li>
                    <h5>Addressing Learning Disparities </h5>
                    <p>Bridging the gap of learning loss among socially and economically disadvantaged groups requires targeted interventions.</p>
                </li>
            </ul>
        </div>
        </>
      );
  }
  function SolutionsList(){
    return (
        <div className="ContsLists solutionList">
            <ul>
                <li>
                    <h5>Balancing two modes </h5>
                    <p>UEDUCATE’s capability to quickly shift to online, offline, and hybrid modes of teaching ensures harmonious teaching and learning and a perfect balancing between the shift. </p>
                </li>
                <li>
                    <h5>Diverse Parental Choices </h5>
                    <p>UEDUCATE offers online, offline, hybrid options, catering to varied parental preferences while upholding individual learning excellence.</p>
                </li>
                <li>
                    <h5>Equitable Teaching Excellence</h5>
                    <p>UEDUCATE’s unique method of exceptional, holistic education sets off the effect of lack of quality instructors. </p>
                </li>
                <li>
                    <h5>Empowering Learning Choices</h5>
                    <p>Diverse teaching modes include offline options, catering to areas with limited internet access, like 2-3 tier cities.</p>
                </li>
                <li>
                    <h5>Tackling Student Absenteeism</h5>
                    <p>Online presence mitigates engagement gaps. No participation loss ensuring continuous learning without hindrance.</p>
                </li>
                <li>
                    <h5>Empowering Adaptability</h5>
                    <p>KATBOOK, KAMPUS & KSMACC offer customizable solutions. Build resilience and navigate disruptions effectively, tailored to each institution's unique requirements.</p>
                </li>
                <li>
                    <h5>Erasing Learning Disparities</h5>
                    <p>UEDUCATE’s mission is an inclusive education ecosystem, accessible, equitable, and affordable. Bridging gaps for disadvantaged groups through targeted interventions.</p>
                </li>
    
            </ul>
        </div>
      );
  }

  const tabs = [
    { title: "Challenges", content: (<ChallengesList/>), },
    { title: "Solutions", content: (<SolutionsList/>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);


  
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>       
        </>
      )}
    </div>
    <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
