import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const Testimonial = () => {

    var HCSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed:6000,
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1050,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };
          

  return (
    <>
    <section class="section secSeven">
              <div className='HapClientSec'>
                <div className='HHCTitle'>
                    <h2 data-aos="fade-right"  data-aos-duration="1000">Happy</h2>
                    <p data-aos="fade-left"  data-aos-duration="1000" data-aos-delay="100">Gain inspiration from the voices of our cherished<br/> learners and tutors, embodying the remarkable<br/> transformations enabled by uEducate!</p>
                </div>
                <div className='HCSliderDiv'>
                 <Slider {...HCSlider} className='HCSliders'>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Teacher</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Perant</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Students</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Teacher</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Perant</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div>
                      <div class="hcitems">
                      <div class="hcIcons">
                        <img src={require ('../../asstes/images/hcPersonIcon.png')} alt='Happy Client'/>
                      </div>
                      <div class="hcConts">
                         <h3>Students</h3>
                         <p>Lorem Ipsum is simply dummy<br/> text of the printing and typesetting<br/> industry. standard  has been<br/> the industry's standard dummy text<br/> has been the industry's standard<br/> dummy text has been<br/> the industry's...</p>
                         <h4>Michael</h4>
                         <div class="star-rating">
                          <input type="radio" id="5-stars" name="rating" value="5" />
                          <label for="5-stars" class="star">&#9733;</label>
                          <input type="radio" id="4-stars" name="rating" value="4" />
                          <label for="4-stars" class="star">&#9733;</label>
                          <input type="radio" id="3-stars" name="rating" value="3" />
                          <label for="3-stars" class="star">&#9733;</label>
                          <input type="radio" id="2-stars" name="rating" value="2" />
                          <label for="2-stars" class="star">&#9733;</label>
                          <input type="radio" id="1-star" name="rating" value="1" />
                          <label for="1-star" class="star">&#9733;</label>
                        </div>
                      </div>
                      </div>
                   </div>
                  </Slider>
                  <p className='exprbtn'><Link to="/">explore +</Link></p>
                </div>
              </div>
          </section>
    </>
  )
}

export default Testimonial