import React from 'react';
import { useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { NavLink,Link } from 'react-router-dom';
import menucloseicon from "../../asstes/images/menucloseicon.svg";
import './header.css';
import EnquirePopupFrom from '../EnquirePopupFrom';

const Header = () => {
    const [modalShow, setModalShow] = React.useState(false);
    
    const [shadow, setShow] = React.useState();

    const [scroll, setScroll] = useState(false)
    useEffect(() => {

        if (window.matchMedia("(min-width: 801px)").matches) {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 10)
            })
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('BHmExpended' , shadow);
        return () => {
            document.body.classList.remove('BHmExpended' , shadow);
        }
    }, [shadow]);

    function useScrollDirection() {
      
        const [scrollDirection, setScrollDirection] = React.useState(null);
        React.useEffect(() => {
          let lastScrollY = window.pageYOffset;
          if (window.matchMedia("(min-width: 801px)").matches) {
                const updateScrollDirection = () => {
                    const scrollY = window.pageYOffset;
                    const direction = scrollY > lastScrollY ? "down" : "up";
                    if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
                    setScrollDirection(direction);
                    }
                    lastScrollY = scrollY > 0 ? scrollY : 0;
                };
                window.addEventListener("scroll", updateScrollDirection); // add event listener
                return () => {
                    window.removeEventListener("scroll", updateScrollDirection); // clean up
                }
            }

        }, [scrollDirection]);
      
        return scrollDirection;
    };
    const scrollDirection = useScrollDirection();
  return (
    <>
        <header className={`ueHeader ${scroll ? "scrolled" : ""} ${ scrollDirection === "down" ? "isSticky" : "noSticky"}`}>
            <Container fluid>
                <Row>
                    <div className='HLogoD'><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link"><img src={require('../../asstes/images/ue-logo.png')} alt="UEducate"/></Link></div>
                    <div className='HMenuD'>
                        <nav className="nav">
                            <ul className="uemainMenu">
                                <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/`} className="nav__link">Home</NavLink></li>
                                <li className="nav__item"><NavLink exact activeClassName="active" to={process.env.PUBLIC_URL + '/about'} className="nav__link">About</NavLink></li>
                                <li className="nav__item dropdown"><NavLink exact to='#' className="nav__link dropbtn">Our Products</NavLink>
                                    <div className="dropdown-content">
                                        <Row>
                                            <Col md={8}>
                                                <div className="megamenuLeft">
                                                    <div className="megamenuItem"> <NavLink exact to={process.env.PUBLIC_URL + '/katbook'} className="nav__link"><span>Our Product</span> Katbook <span class="arrow arrow-black"></span></NavLink></div>
                                                    <div className="megamenuItem"> <NavLink exact to={process.env.PUBLIC_URL + '/kampus'} className="nav__link"><span>Our Product</span> Kampus <span class="arrow arrow-black"></span></NavLink></div>
                                                    <div className="megamenuItem"> <NavLink exact to={process.env.PUBLIC_URL + '/ksmacc'} className="nav__link"><span>Our Product</span> Ksmacc <span class="arrow arrow-black"></span></NavLink></div>
                                                    <div className="megamenuItem"> <NavLink exact to={process.env.PUBLIC_URL + '/ustudy'} className="nav__link"><span>Our Product</span> Ustudy <span class="arrow arrow-black"></span></NavLink></div>
                                                </div>
                                            </Col>                                                
                                            <Col md={4}>
                                                <img src={require('../../asstes/images/menu-men-img.jpg')} alt="UEducate"/>
                                            </Col>
                                        </Row>
                                    </div>
                                </li>
                                {/* <li className="nav__item"><NavLink exact to='#' className="nav__link">Verticals</NavLink></li> */}
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/oursolutions'} className="nav__link">Solutions</NavLink></li>
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/contactus'} className="nav__link">Let's Talk</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                    <div className='HHumMD'>
                        <Link className='HumMenu' to="#" onClick={() => setShow(true)} >
                            <div className='linelist'>
                                <div className='line line-one'></div>
                                <div className='line line-two'></div>
                                <div className='line line-three'></div>
                            </div>
                        </Link>
                    </div>
                </Row>
            </Container>
        </header>
        <div className={`HamburgerMegamenu ${shadow ? "mmmm-opened" : ""}`}  id="HamburgerMegamenu">	
            <div className="mm-main-container">
                <div className="megamenu-header">
                    <Link to="/"><img src={require('../../asstes/images/ue-HumLogo.png')} alt="UEducate"/></Link>
                    <button className="Hamburgermm-close" id="Hamburgermm-close" onClick={() => setShow(false)}><img src={menucloseicon} alt=""/> <span>Close</span></button>
                </div>
                <div className="megamenu-container">
                    <div className="mmMenuCon">
                        <div className="MainMenuList">
                            <ul className="mMenuListUl">
                                <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/`} className="nav__link" onClick={() => setShow(false)}>Home</NavLink></li>
                                <li className="nav__item"><NavLink exact activeClassName="active" to={process.env.PUBLIC_URL + '/about'} className="nav__link" onClick={() => setShow(false)}>About</NavLink></li>
                                <li className="nav__item"><NavLink exact to='#' className="nav__link">Our Products</NavLink></li>
                                {/* <li className="nav__item"><NavLink exact to='#' className="nav__link">Verticals</NavLink></li> */}
                            </ul>
                            <ul className="mMenuListUl">
                                <li className="nav__item"><NavLink exact activeClassName="active" to={process.env.PUBLIC_URL + '/oursolutions'} className="nav__link"  onClick={() => setShow(false)}>Solutions</NavLink></li>
                                <li className="nav__item"><NavLink exact to='#' className="nav__link">News & Insights</NavLink></li>
                                <li className="nav__item"><NavLink exact to='#' className="nav__link">Careers</NavLink></li>
                                <li className="nav__item"><NavLink exact activeClassName="active" to={process.env.PUBLIC_URL + '/publishwithus'} className="nav__link" onClick={() => setShow(false)}>Publish with us</NavLink></li>
                            </ul> 
                            <ul className="mMenuListUl">
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/contactus'}  onClick={() => setShow(false)} className="nav__link">Contact us</NavLink></li>
                                <li className="nav__item"><NavLink exact to='#' className="nav__link">Disclaimer</NavLink></li>
                                <li className="nav__item"><NavLink exact to='#' className="nav__link">Privacy Policy</NavLink></li>
                            </ul>       
                        </div>
                        <div className="OurProductsList">
                            <ul className="OPLul">
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/katbook'} className="nav__link" onClick={() => setShow(false)}>Katbook</NavLink></li>
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/kampus'} className="nav__link" onClick={() => setShow(false)}>Kampus</NavLink></li>
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/ksmacc'} className="nav__link" onClick={() => setShow(false)}>Ksmacc</NavLink></li>
                                <li className="nav__item"><NavLink exact to={process.env.PUBLIC_URL + '/ustudy'} className="nav__link" onClick={() => setShow(false)}>Ustudy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mmENContin">
                        <div className="mmENCLeft">
                            <h6>Get On The Digital Mode of</h6>
                            <h2>Teaching & Learning</h2>
                            <NavLink className="EnquirenowL" to='#' onClick={() => setModalShow(true)} >enquire now</NavLink>
                        </div>
                        <div className="mmENCRight">
                            <div className="ENEnowInLink">
                                <h6>EXPERIENCE katbookstore APP ON MOBILE</h6>
                                <Link to='https://www.katbookstore.com/' target='_blank'><img src={require('../../asstes/images/digital-katebook-img.png')} alt="digital-katebook-img"/></Link>
                                <ul>
                                    <li><Link to=''><img src={require ('../../asstes/images/GPlayImg.webp')} alt="Google Play"/></Link></li>
                                    <li><Link to=''><img src={require ('../../asstes/images/AppStoreImg.webp')} alt="App Store"/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        
        <div id="mobile-nav-sticky" class="mobile-nav-sticky">
            <ul class="mns-list">
                <li><Link to="tel:+9198848 00819"><div class="mnv-icon-view mnv-icon-call"></div><strong>Call Us</strong></Link></li>	
                <li><Link to="#" onClick={() => setModalShow(true)} class="mnv-enquires"><div class="mnv-icon-view mnv-icon-enquire"></div><strong>Enquiry</strong></Link></li>	
                <li class="mnv-logo"><Link to='/#HomepageTop'><div class="mnv-logo-img"></div></Link></li>	
                <li><Link to="#" id="mns-hamburger-icon" class="hamburger-icon mns-hamburger-icon" onClick={() => setShow(true)}><b><span class="line line-one"></span><span class="line line-two"></span><span class="line line-three"></span></b><strong>Menu</strong></Link></li>
                <li><Link to="https://www.katbookstore.com/" target='_blank' class="mnv-icon-searchs"><div class="mnv-icon-view mnv-icon-search"></div><strong>Store</strong></Link></li>	
            </ul>
        </div>
    <EnquirePopupFrom
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />

</>
  )
}

export default Header