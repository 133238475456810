import React, { Component } from "react";
import Slider from "react-slick";

export default class Milestone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="OSSliders" data-aos="fade-down"  data-aos-duration="1000">
        <div className="ourjournyImgSlide" >
        <Slider className="ourjournySlide"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          fade={true}
          arrows={true}
          infinite={false}
          autoplay={false}
          responsive = {[
            {breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                }
            }
        ] }
        >
            <div><div className="ourjournySlideImgs"> <img src={require ('../../asstes/images/ourjourny-img1.png')} alt=""/> <h2 title="1961">1961</h2></div></div>
            <div><div className="ourjournySlideImgs"> <img src={require ('../../asstes/images/Leadership.png')} alt=""/> <h2 title="1992">1992 </h2></div></div>
            <div><div className="ourjournySlideImgs"> <img src={require ('../../asstes/images/Products.png')} alt=""/> <h2 title="2012">2012</h2></div></div>
            <div><div className="ourjournySlideImgs"> <img src={require ('../../asstes/images/DigitalEcoSystem.png')} alt=""/> <h2 title="2020">2020</h2></div></div>
        </Slider>
        </div>
        <div className="ourjournyContSlide" data-aos="fade-down"  data-aos-duration="1000">
        <Slider className="ourjournyCSlide"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={4}
          infinite={false}
          autoplay={false}
          swipeToSlide={true}
          focusOnSelect={true}
          vertical={true}
            autoplaySpeed={5000}
            responsive = {[
                {breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ] }
        >
            <div className="yearBox1"><h4>1961</h4>
            <h3>Establishment</h3>
            Ueducate is part of a visionary consortium founded in 1961, dedicated to offering a spectrum of services ranging from consultancy expertise to the creation of cutting-edge sugar mills, textile spinning mills, and the construction of real estate projects.
    </div>
            <div className="yearBox2"><h4>1992</h4>
            <h3> Leadership</h3>
            Guided by Mr. Sarath Kakumanu, our visionary leader, The group boarded on a transformative journey into the community of software development by establishing Construction Management Software (CMS), tailored to cater to every facet of the construction industry's needs.
            </div>
            <div className="yearBox3"><h4>2012</h4>
            <h3>Products</h3>
            Pioneered in developing KPOST for open communication and KSMACC for closed intra-organizational systems, advancing communication technology significantly.
            </div>
            <div className="yearBox4"><h4>2020</h4>
            <h3>Digital Eco-System</h3>
            Introducing an innovative and patented solution: UEDUCATE, a cutting-edge digital ecosystem meticulously designed to deliver top-tier educational experiences.
            </div>
          
        </Slider>
        </div>
      </div>
    );
  }
}