import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {Link} from 'react-scroll';
import TalktoExpert from '../TalktoExpert';
import BookaDemo from '../BookaDemo';

import { useEffect } from 'react';


const Publishwithus = () => {
    useEffect(() => {
        document.body.classList.add('PblsusPage')
        return () => {
            document.body.classList.remove('PblsusPage')
        }
    }, [])

  return (
    <div className='publishwusPage'>
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../asstes/images/kampusBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Empower Your <br/>Expertise & Precision</h5>
                        <h1>Publish with Us</h1>
                        <h5 className='tbheadFive tbHeadRight'>Your Path to our <br/>Educational Platform</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='PublishwusSec'>
            <Container>
                <Row className='outrow'>
                    <Col md='3' className='outcol'>
                        <div className='pwsListCol'>
                            <ul>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContOne">Becoming a <br/>Content Provider</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContTwo">Becoming a <br/>KATBOOK Compiler</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContThree">Types of KATBOOK <br/>Content</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContFour">Content Compiler <br/>Review process</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContFive">Why Registration with <br/>KCM is Necessary</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContSix">Who can access <br/>the content?</Link></li>
                                <li><Link activeClass="active" spy={true} smooth={true} duration={500} to="ListContSeven">Content <br/>Copyright Policy</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md='9' className='outcol'>
                        <div className='pwusItems'>
                            <div className='pwusConts ListContOne' id='ListContOne'> 
                                <div className='textBlack'>
                                    <h3>Becoming a Content <span>Provider <br/>in KATBOOK</span></h3>
                                    <p>KATBOOK has emerged as a dynamic platform, providing opportunities for content providers to reach a global audience. It sets a path for how individuals and organizations can offer their valuable resources to the world through this innovative platform.</p>
                                </div>
                                <Row>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgOne.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Joining the KATBOOK Content Provider Community</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The first step in becoming a content provider on KATBOOK is to join the thriving community. KATBOOK welcomes a diverse range of content providers, whether they offer educational materials, literary works, or multimedia resources for education and training.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Content Accessibility: Free or Paid?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Content providers have the flexibility to choose whether their offerings will be available for free or at a fee to users. This decision depends on the nature of the content and the provider's objectives. KATBOOK accommodates both models, enabling content providers to monetize their creations or contribute to the global knowledge pool altruistically.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Content Diversity: The World of Options</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>One of the distinguishing features of KATBOOK is its extensive variety of content types. Content providers can specialize in a single type or diversify their offerings, providing the range of content required for a KATBOOK. Such versatility ensures that KATBOOK remains a one-stop destination for knowledge seekers, catering to diverse learning needs.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>The Registration Process</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Before starting their content-sharing journey, providers must register themselves with KATBOOK's Content Management (KCM) system. This registration process involves providing essential details, including:</p>
                                                    <ul>
                                                        <li><strong>Name of the Provider:</strong> Your official or organizational name</li>
                                                        <li><strong>Address:</strong> Your physical location or headquarters</li>
                                                        <li><strong>Contact Information:</strong> Phone number and email for communication</li>
                                                        <li><strong>Content Category:</strong> Specify the type of content you plan to offer (e.g., school, UG, graduation, post-graduation, Ph.D., etc.).</li>
                                                    </ul>                                                    
                                                    <p>Upon successful registration, you will receive a unique Content Provider credentials via KATBOOK's communication channels, KMAIL and KATCHUP.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Royalties and Collaboration</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>In the collaborative spirit of KATBOOK, content providers enjoy a unique compensation model. When their content is chosen for inclusion in a KATBOOK, the Compiler of that KATBOOK shares royalties based on mutually agreed payment terms. This approach fosters collaboration and encourages high-quality content creation.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Real-time Monitoring and Reporting</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KATBOOK ensures transparency for content providers by offering real-time account monitoring and reporting. This feature allows content providers to track the performance of their content, view sales, and access reports conveniently through KMAIL and KATCHUP.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContTwo' id='ListContTwo'> 
                                <div className='textBlack'>
                                    <h3>Becoming a KATBOOK <span>Compiler <br/>in KATBOOK</span></h3>
                                    <p>The role of a KATBOOK compiler is significant. KATBOOK Compilers are instrumental in the flawless compilation and publication of comprehensive educational resources known as KATBOOKs, catering to a diverse array of educational needs. This in-depth exploration sheds light on the multifaceted responsibilities and significance of KATBOOK compilers within the KATBOOK Content Management (KCM) ecosystem.</p>
                                </div>
                                <Row className='reverseRow'>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgTwo.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Who is the KATBOOK compiler?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>A KATBOOK compiler is essentially a content curator who assembles diverse educational materials into a cohesive KATBOOK. These materials may revolve around content from multiple content providers, including base books, additional content, concept videos, teaching videos, study material, self-assessment questions, previous year's question papers, and mock exam papers. The compiler peculiarly selects, organizes, and integrates these components to create a holistic educational resource.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Public and private use</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KATBOOKs, once compiled, can serve various purposes. Compilers have the flexibility to categorize their creations for public or private use.</p>
                                                    <ul>
                                                        <li><strong>Private Use:</strong> In this mode, the compiled KATBOOK remains exclusive to specific users or institutions. It is not available for public purchase or free usage. Access is restricted to those individuals or organizations granted permission by the compiler, making it an ideal choice for tailored educational solutions.</li>
                                                        <li><strong>Public Use:</strong> Conversely, a compiled KATBOOK can be designed for public use. This means it becomes accessible to a broader audience, including educational institutions and individuals. Public users can easily discover and access these KATBOOKs through the KATBOOK Store, either freely or at a designated price, in line with the pricing structure determined for the KATBOOK.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>The Registration Process</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>To become a KATBOOK compiler, one must meet specific criteria:</p>
                                                    <ul>
                                                        <li><strong>Content Provider Registration:</strong> First and foremost, aspiring compilers need to register as content providers in the KATBOOK Content Management System (KCM). This initial step ensures that the compiler has access to a repository of educational content to curate.</li>
                                                        <li><strong>Access the KCM Platform:</strong> Once registered as a content provider, the compiler gains access to the KCM platform, which serves as a comprehensive hub for educational content.</li>
                                                        <li><strong>Select the 'KATBOOK Compiler' Option:</strong> Within the KCM platform, aspiring compilers can select the 'KATBOOK Compiler' option from the registration menu.</li>
                                                        <li><strong>Provide Relevant Details:</strong> Compilers are required to provide essential details, such as the category of the KATBOOK they intend to compile (e.g., school, UG, graduation, post-graduation, Ph.D., etc.).</li>
                                                        <li><strong>Submission:</strong> After furnishing the necessary information, the compiler submits their registration details.</li>
                                                        <li><strong>Compiler Credentials:</strong> Upon successful registration, the compiler is assigned with unique credentials for compiling KATBOOKs and is notified through KMAIL and KATCHUP.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Why compilation?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The act of compilation undertaken by KATBOOK Compilers holds several advantages:</p>
                                                    <ul>
                                                        <li><strong>Efficiency:</strong> Compilers streamline the educational content creation process by aggregating materials from various sources, saving time and effort for educators and learners.</li>
                                                        <li><strong>Customization:</strong> Compilers can tailor KATBOOKs to specific educational levels, ensuring that the content aligns with the target audience's needs.</li>
                                                        <li><strong>Accessibility:</strong> By compiling resources, educational content becomes more accessible to a wider audience, enriching the learning experience.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContThree' id='ListContThree'> 
                                <div className='textBlack'>
                                    <h3>Types of <span>KATBOOK Content</span></h3>
                                    <p>KATBOOKs, known for its comprehensive educational content, consists of a diverse array of content types carefully curated to enhance the learning experience. These various content components work together to provide a holistic and engaging educational resource.</p>
                                </div>
                                <Row>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgThree.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Base Book</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The base of any KATBOOK is the base book. This component comprises a session-wise mapped digital textbook, ensuring that the content aligns seamlessly with the curriculum. It serves as the foundation upon which additional content is built, providing a structured and organized learning path.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Additional Content</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>To supplement the base book, additional content is included. This supplementary material, often in the form of text, images, and videos, is strategically placed to provide in-depth explanations and examples of specific keywords and concepts. It enriches the learning experience by offering diverse perspectives and multimedia resources.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Concept Videos</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Concept videos are bite-sized video segments that delve into specific concepts. These videos are tailored to match the context of each part of the session, making complex concepts more accessible. They offer visual explanations that reinforce textual content, enhancing comprehension.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Teaching Videos</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Teaching videos complement the base book by providing segmented video lessons that align with the material covered in the textbook. They offer an engaging way for learners to absorb information as well as insights from educators that facilitate a deeper understanding of the subject matter.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Study Material</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Study materials are essential for active learning. KATBOOKs incorporate chapter-end solved questions that encourage students to apply their knowledge. These questions serve as self-assessment tools, allowing learners to gauge their understanding and prepare for assessments effectively.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Self-Assessment Q&A</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Self-assessment is a crucial part of the learning process. KATBOOKs provides a range of question types, including multiple-choice, short-answer, and essay questions, along with detailed explanations. These assessments enable learners to practice, reinforce their knowledge, and encourage a deeper grasp of the subject matter.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>Previous Year's Question Papers</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Preparing for exams is easier with access to previous years' question papers. KATBOOKs often include solved question papers from previous exams, helping students familiarize themselves with the examination format and assess their readiness.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContFour' id='ListContFour'> 
                                <div className='textBlack'>
                                    <h3>Content Compiler <span>Review <br/>Process</span></h3>
                                    <p>Content providers play an essential role in the KATBOOK ecosystem by furnishing valuable educational materials. Their contributions can take the form of digital textbooks, videos, assessments, and more. To incentivize and fairly compensate content providers, a systematic process has been established. Here's a detailed overview of our review process and how content providers are remunerated for their efforts:</p>
                                </div>
                                <Row className='reverseRow'>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgFour.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Content Evaluation and Selection</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>When content providers submit their materials to KCM (KATBOOK Content Management), the KATBOOK compilers carefully evaluate the suitability and quality of the content. The selection process is driven by the educational value and relevance of the material to the intended audience.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Price Negotiation</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Once content is deemed suitable, the critical step of reaching a mutually agreeable price for the content begins. It is essential that both parties, the content provider and the KATBOOK compiler/publishers, are satisfied with the financial arrangement.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Compilation and Publication</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Following a successful negotiation, the selected content is integrated into a KATBOOK. This compilation process involves combining the content of multiple providers to create comprehensive educational resources. After compilation, the KATBOOK is prepared for publication.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Availability in the KATBOOK Store</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Published KATBOOKs are made available in the KATBOOK Store, providing customized accessibility to both individual users and institutional customers. This platform serves as a marketplace for educational materials, reaching a wide audience of learners.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Revenue Sharing</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The heart of the compensation process lies in revenue sharing. When a KATBOOK is purchased or accessed by users, the agreed-upon cost of the content is calculated and allocated to the content provider's account. This revenue-sharing mechanism ensures that content providers are compensated directly based on the consumption of their materials.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Real-time account monitoring</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Content providers are not left in the dark about their earnings. They have access to a user-friendly interface where they can monitor their accounts in real-time. This transparency allows content providers to easily track the financial aspects of their contributions.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>Reporting and Delivery</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>For added convenience, content providers can receive detailed reports and digital copies of their earnings via KMAIL and KATCHUP. This streamlined process ensures that they are kept well informed about their compensation.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContFive' id='ListContFive'> 
                                <div className='textBlack'>
                                    <h3>Why Registration with <span>KCM is <br/>Necessary</span></h3>
                                    <p>KCM (KATBOOK Content Management) has emerged as a pioneering platform connecting content providers and KATBOOK compilers. This collaboration serves as the foundation for the KATBOOK ecosystem. Here, we delve into the essential reasons why registration with KCM is imperative for both content providers and compilers and the benefits it offers.</p>
                                </div>
                                <Row>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgFive.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Privilege and Access</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>One primary reason for registering with KCM is to gain privileged access to a strong content management system. KCM provides a secure and user-friendly environment customized specifically for content providers and KATBOOK compilers. This specialized access allows registered users to upload, manage, and curate content efficiently.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Smooth Content Upload and Management</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>For content providers, registration with KCM streamlines the process of uploading their valuable content. They can contribute their educational materials, textbooks, videos, and more, ensuring their resources are readily available for KATBOOK compilers. This centralized platform simplifies content organization, reduces administrative overhead, and streamlines the dissemination of knowledge.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Collaborative Opportunities</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KCM bridges the gap between content providers and KATBOOK compilers, fostering collaborative ventures. Registered providers can collaborate with compilers to create comprehensive KATBOOKs that meet the diverse needs of learners. This synergy promotes the development of high-quality educational resources and enhances the overall educational experience.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Real-Time Account Management</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Transparency and accountability are paramount in the KATBOOK ecosystem. Registration with KCM grants users real-time access to their accounts. Content providers and compilers can monitor the performance of their uploaded content, view sales reports, and verify royalties earned from their contributions. This transparency instills trust and confidence in the system.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Revenue Sharing</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>One of the standout advantages of registration lies in the revenue-sharing mechanism. KATBOOK compilers select content from various providers to compile a KATBOOK. When these compiled KATBOOKs are published and sold, the agreed-upon compensation is credited directly to the accounts of the registered content providers and compilers. This financial incentive rewards their commitment to enriching the educational community.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Direct Communication</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KCM encourages a cohesive community of content creators and compilers. Registered users have the privilege of engaging in direct communication with one another through the platform. This open channel of communication enables negotiations, content agreements, and collaborative discussions, further developing the KATBOOK network.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContSix' id='ListContSix'> 
                                <div className='textBlack'>
                                    <h3>Who can <span>access the content?</span></h3>
                                    <p>In the KATBOOK publishing ecosystem, access to content within the KATBOOK Content Management platform is regulated to ensure both security and appropriate usage. Understanding who can access this content is crucial for maintaining the integrity of the system and providing for the diverse needs of content providers, compilers, and end-users.</p>
                                </div>
                                <Row className='reverseRow'>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgSix.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Content Owner/Provider</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Content owners and providers hold the primary rights to the material they contribute to the KCM platform. As the creators or licensed distributors of the content, they have full access to their own submissions. This access enables them to have content control and ensures that creators can refine their offerings to meet the evolving needs of users.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>KATBOOK Compiler</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KATBOOK compilers play a pivotal role in assembling comprehensive KATBOOKs from various content sources. While they can access the content, their access may be limited to reviewing sample portions. Full access to content is typically granted through access links provided by content owners or providers. This access control ensures that compilers can evaluate content suitability while respecting copyright and licensing agreements.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>KATBOOK Marketplace Admin</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>KATBOOK Marketplace administrators are responsible for overseeing the operational aspects of the platform. They require access to manage and maintain the system, including addressing technical issues, resolving disputes, and ensuring that content adheres to platform policies. However, their access is typically restricted to administrative functions and not to the content itself, unless required for support or moderation purposes. For all practical purposes Marketplace Admin is a moderator and does not have access to content.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pwusConts ListContSeven' id='ListContSeven'> 
                                <div className='textBlack'>
                                    <h3>Content <span>Copyright Policy</span></h3>
                                    <p>Ensuring the integrity of the intellectual property, particularly within the context of KATBOOK's content ecosystem, is important. The Content Copyright Policy establishes guidelines and principles for content providers, KATBOOK compilers, and all stakeholders involved in the KATBOOK publishing process. It serves as a comprehensive framework to protect copyright holders' rights and maintain the ethical use of content. It is the combined responsibility of Content providers and KATBOOK Compilers to ensure that Content Copyright Policy is adhered to.</p>
                                </div>
                                <Row>
                                    <Col md="4">
                                        <figure><img src={require ('../../../asstes/images/pwusImgSeven.jpg')} alt="Becoming a Content Provider in KATBOOK" /></figure>
                                    </Col>
                                    <Col md="8">
                                        <Accordion defaultActiveKey="2">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Responsibility of Content Providers</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li><strong>Copyright Ownership:</strong> Content providers must absolutely possess the copyright to the material they intend to contribute to KATBOOK's repository. This requirement is fundamental to protecting both the provider and the end-users.</li>
                                                        <li><strong>Licenses and Permissions:</strong> In cases where content is not owned outright, providers must secure the necessary licenses and permissions from the original creators or publishers. This ensures that the content is legally and ethically incorporated into KATBOOK materials.</li>
                                                        <li><strong>Transparency:</strong> Providers must be transparent about the copyright status of their content. They should clearly indicate if the content is copyrighted, in the public domain without any licensing restrictions, or if they possess proper licensing rights.</li>
                                                        <li><strong>Attribution:</strong> Content providers should meticulously attribute the original creators or publishers of the content. Proper attribution is a sign of respect for intellectual property rights and is essential for transparency.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Content Usage and Licensing</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li><strong>Public Use:</strong> Content designated for public use within KATBOOK should adhere to specific licensing terms set by the content providers. These terms can include variations on Creative Commons licenses or other open-access agreements.</li>
                                                        <li><strong>Private Use:</strong> Content marked for private use should only be accessible to authorized individuals or institutions as agreed upon by the content provider. It is not available for public consumption or purchase.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Accountability and Compliance</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li><strong>Reporting Violations:</strong> Users and stakeholders are encouraged to report any suspected copyright violations promptly. KATBOOK takes these reports seriously and will investigate and address any legitimate concerns.</li>
                                                        <li><strong>Remediation:</strong> In the event of a copyright violation, KATBOOK will take appropriate remedial action, which may include removing the infringing content and notifying the content provider.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Bibliography and Documentation</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li><strong>Complete Documentation:</strong> Content providers should maintain a comprehensive list of sources used in their content. This list, often referred to as a bibliography, should be included as part of the material to provide due credit to the original creators and to facilitate traceability.</li>
                                                        <li><strong>Citation Standards:</strong> Following recognized citation standards ensures that proper credit is given to original sources. These standards may vary depending on the type of content and academic discipline.</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <p class="reg_btn"><a href="https://www.katbookstore.com/" target='_blank' rel="noopener">Register Now</a></p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section> 
    </div>
  )
}

export default Publishwithus