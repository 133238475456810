import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Katbookpowerlist from './Katbookpowerlist';
import KatbookTabs from './KatbookTabs';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';
import TalktoExpert from '../TalktoExpert';
import BookaDemo from '../BookaDemo';

const Katbook = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [shadow, setShow] = React.useState(); 
  return (
    <div className="KatbookPage">
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../asstes/images/katbookBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Future of Enhanced <br/>learning</h5>
                        <h1>KATBOOK</h1>
                        <h5 className='tbheadFive tbHeadRight'>A Collaborative Advanced <br/>Digital Textbook</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='ProIntroSec'>
            <div className='ProContSec ktbkIntroC'>
                <Container>
                    <div className='textBlack tbTitle'>
                        <h2 data-aos="fade-down"  data-aos-duration="1000">Welcome to <span>KATBOOK</span></h2>
                        <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Your Gateway to Comprehensive Digital Learning</h4>
                        <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">That combines the power of enhanced content, concept and teaching videos & in-book <br/>clarification tool with the convenience of anywhere, anytime access. Our platform revolutionizes the method<br/> of teaching and learning, making it simple and effective with integrated communication support. <br/>With KATBOOK, students can learn at their own pace, engaging with interactive content and <br/>collaborating with fellow learners. It empowers students to connect with teaching faculties, <br/>facilitating personalized guidance and support throughout their educational journey.</p>
                    </div>
                    <div className='textBlack ktbkList'>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/KbookIntroIconOne.svg').default } alt="360-degree additional information on all Keywords"/><strong>360-degree additional<br/> information on<br/> all Keywords.</strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="400"><img src={require ('../../../asstes/images/KbookIntroIconTwo.svg').default} alt="Sliced concept and teaching videos embedded within sessions"/><strong>Sliced concept and<br/> teaching videos <br/>embedded within <br/>sessions. </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="500"><img src={require ('../../../asstes/images/KbookIntroIconThree.svg').default} alt="In-book doubt clearing and collaboration mechanism"/><strong>In-book doubt<br/> clearing and <br/>collaboration<br/> mechanism. </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600"><img src={require ('../../../asstes/images/KbookIntroIconFour.svg').default} alt="Enhanced features bridge the gap between digital and tangible reading experiences"/><strong>Enhanced features<br/> bridge the gap <br/>between digital <br/>and tangible <br/>reading experiences.</strong></li>
                        </ul>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700"><img src={require ('../../../asstes/images/KbookIntroIconFive.svg').default} alt="Offline, Online, and hybrid modes of the book are available"/><strong>Offline, Online, <br/>and hybrid modes <br/>of the book <br/>are available.  </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="800"><img src={require ('../../../asstes/images/KbookIntroIconSix.svg').default} alt="Provision to raise doubts from all parts of the textbook/learning material."/><strong>Provision to raise <br/>doubts from all <br/>parts of the<br/>textbook/learning<br/> material. </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="900"><img src={require ('../../../asstes/images/KbookIntroIconSeven.svg').default} alt="Notification of the clarification sought to all stakeholders."/><strong>Notification of <br/>the clarification <br/>sought to all <br/>stakeholders. </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="1000"><img src={require ('../../../asstes/images/KbookIntroIconEight.svg').default} alt="FAQ from previous doubts raised "/><strong>FAQ from previous<br/> doubts raised.  </strong></li>

                        </ul>
                     </div>
                    <div className='textBlack ktbkYTDiv'>
                      <Link to=''><img src={require ('../../../asstes/images/ktbkIntroYTIcon.png')} alt='KATBOOK You Tube'/></Link>
                     </div>
                </Container>
            </div>
        </section>
        <section className='ProFeatuSec'>
            <Container>
                <div className='textBlack tbTitle textCenter'>
                    <h2 data-aos="fade-down"  data-aos-duration="1000">Unique Features of<br/> <span>KATBOOK</span></h2>
                    <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Unleash Your Individuality with Unmatched Features</p>
                </div>
                <div className='textBlackrow'>
                    <Row>
                        <Col>
                            <div className='featboxitems fbitemOne'>
                                <div className='featboximg'>
                                    <img src={require ('../../../asstes/images/ktbkFeatboxImg1.png')} alt="KATBOOK Feature"/>
                                    <h3>PARAMOUNT  <br/> ATTRIBUTE</h3>
                                </div>
                                <div className='featboxpara'>
                                    <p>KATBOOK serves as a comprehensive solution for all teaching and learning requirements. With identified keywords, additional relevant content is readily made available, eliminating the need to search the internet for further information. The concept videos are seamlessly integrated into each session of the book, eliminating the need to search for suitable videos elsewhere.</p>
                                    <p>Furthermore, teaching videos by experts are made available that eliminate the effect of the absence of a teacher at any point of time in the academic year. Through strategic collaborations with renowned publishers, authors, and content providers, the quality of the existing books is enriched by incorporating immersive interactive content.</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='featboxitems fbitemTwo'>
                                <div className='featboximg'>
                                    <img src={require ('../../../asstes/images/ktbkFeatboxImg2.png')} alt="KATBOOK Feature"/>
                                    <h3>HOLISTIC <br/> LEARNING</h3>
                                </div>
                                <div className='featboxpara'>
                                    <p>KATBOOK prioritizes learning paths with complete 360-degree content availability with identified keywords. The lessons are enhanced with concept and teaching videos making the learning process more immersive and enjoyable. </p>
                                    <p>Clear explanations, insightful examples, doubt clarification, and self-assessment aligned with pedagogical best practices promote active engagement, critical thinking, and long-term retention of knowledge. </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
        <section className='ProAIPowSec '>
            <Container>
                <Row>
                    <Col md={12}>                        
                        <div className='textBlack tbTitle'>
                            <h4 data-aos="fade-down"  data-aos-duration="1000">Elevate Your Learning with</h4>
                            <h2 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Katbook's AI-Powered <small>Educational Solutions</small></h2>
                            <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300">What’s included in our holistic gateway to a great education?</p>
                        </div>
                    </Col >
                    <Col md={9} className='ProAIPowSCol katbokAipow'>
                        <Katbookpowerlist/>
                        <div className='ProAIPowIMg'><img src={require ('../../../asstes/images/ProAIPow.png')} alt="KATBOOK's AI-Powered"/></div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='ProRobSolSec KatbookRobSolSec'>
            <div className='RobSolSlides'>
                <div className={`RobSolSlide RSSlideOne ${shadow ? "RSSlideLHide" : "RSSlideLOpen"}`}  >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000"><span>KATBOOK</span> Empowering Institutions<br/> With Robust Solutions</h2>
                                    <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Isn't it time to revolutionize education?</p>
                                </div>
                            </Col>
                            <Col md={6} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KBRobSolImg.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                            <Col md={6} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon1.svg').default} alt=""/>
                                        <h4>Growth-Oriented<br/> Platform</h4>
                                        <p>Scalable and<br/> High-Quality<br/> for All Stakeholders.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon2.svg').default} alt=""/>
                                        <h4>Accessible to<br/> All Stakeholders</h4>
                                        <p>Students, Teachers,<br/> Administrators, and<br/> Parents.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon3.svg').default} alt=""/>
                                        <h4>Multi-Platform Availability</h4>
                                        <p>Web, Android, and<br/> iOS Support.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon4.svg').default} alt=""/>
                                        <h4>Cost-Effective</h4>
                                        <p>Solution for<br/> Institutions and<br/> Students.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon5.svg').default} alt=""/>
                                        <h4>Accessibility</h4>
                                        <p>KATBOOK is accessible <br/> On-Site or via the <br/> Cloud Platform.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon6.svg').default} alt=""/>
                                        <h4>Versatile Usage<br/> across Devices</h4>
                                        <p>Laptops/Desktops,<br/> Digital Tablets,<br/> Mobile Phones.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon8.svg').default} alt=""/>
                                        <h4>Institution<br/> Managed Book<br/> Authoring</h4>
                                        <p>Self-Contained<br/> Tool to create <br/>school-managed book.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon7.svg').default} alt=""/>
                                        <h4>Affordable<br/> World-Class Platform</h4>
                                        <p>On Campus, Off Campus<br/> & Hybrid Teaching.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='/' className='linkbtn' onClick={() => setShow(true)}><strong>Corporate</strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
                <div className={`RobSolSlide RSSlideTwo ${shadow ? "RSSlideROpen" : "RSSlideRHide"}`} >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000"><span>KATBOOK</span> Scalable solutions for the training needs of your organization</h2>
                                    <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Isn't it time to revolutionize education?</p>
                                </div>
                            </Col>
                            <Col md={6} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KBRobSolImgOne.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                            <Col md={6} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon9.svg').default} alt=""/>
                                        <h4>Empowering Lifelong<br/> Learning</h4>
                                        <p>Upskilling for All<br/> in the Organization<br/> is enabled as per requirement.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon10.svg').default} alt=""/>
                                        <h4>Flexible Learning</h4>
                                        <p>Overcomes the <br/>constraints of time <br/>and availability with <br/>Online, Offline, and <br/>Hybrid Modes to <br/>suit the needs. </p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon11.svg').default} alt=""/>
                                        <h4>Skill Development on Schedule</h4>
                                        <p>A periodic training calendar be generated to assess growth and success.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon12.svg').default} alt=""/>
                                        <h4>Empowered Self-Assessment</h4>
                                        <p>Post-course assessment and report generation<br/> for each course shall be <br/>made available. </p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon13.svg').default} alt=""/>
                                        <h4>Validate Your Achievements</h4>
                                        <p>Certification Upon Assessment Completion<br/> of a specific course <br/>attended and be <br/>generated. </p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon14.svg').default} alt=""/>
                                        <h4>Comprehensive Training Reports</h4>
                                        <p>Admin-Generated Insights are made available on training attendees, course completed, the course in progress, assessments, etc for every Organization.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon15.svg').default} alt=""/>
                                        <h4>Seamless Integration</h4>
                                        <p>Monitoring of attendance<br/> to training and Course <br/>Progress with any<br/> ERP can be enabled.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon16.svg').default} alt=""/>
                                        <h4>Equal Learning Opportunities</h4>
                                        <p>Empowering corporate workforce by overcoming faculty availability constraints.
</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='#' className='linkbtn' onClick={() => setShow(false)}><strong>Institutions </strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
            </div>
        </section>
        <section className='ProApplinkSec'>
            <Container>
                <div className='textBlack tbTitle'>
                    <h4 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">Get your <br/><span className='colorchange'>K</span>ollaborative <br/><span className='colorchange'>A</span>dvanced <br/><span className='colorchange'>T</span>ext<span className='colorchange'>book</span> NOW!</h4>
                    <ul data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
                        <li><Link to='https://www.katbookstore.com/' target='_blank'><img src={require('../../../asstes/images/digital-katebook-img.png')} alt="digital-katebook-img"/></Link></li>
                        <li><Link to='#'><img src={require ('../../../asstes/images/GPlayImg.webp')} alt="Google Play"/></Link></li>
                        <li><Link to='#'><img src={require ('../../../asstes/images/AppStoreImg.webp')} alt="App Store"/></Link></li>
                    </ul>
                </div>
                <div className='applinkdiv'><img src={require('../../../asstes/images/proAppLinkImg.png')} alt="App Link"/></div>
            </Container>
        </section>
        <section className='WChoKBSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Why Choose <span>KATBOOK?</span></h2>
                    <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Unveiling the Benefits</h4>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Where the stakeholders thrive together through seamless collaboration and<br/> transformative benefits.</p>
                </div>
                <div className='WCHooTab'>
                    <KatbookTabs />
                </div>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>    
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  )
}

export default Katbook