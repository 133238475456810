import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Kamaccpowerlist from './Kamaccpowerlist';
import Testimonial from '../../../Components/UEFoot/Testimonial';
import KamaccTabs from './KamaccTabs';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';
import TalktoExpert from '../TalktoExpert';
import BookaDemo from '../BookaDemo';

const Kamacc = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [shadow, setShow] = React.useState(); 
  return (
    <div className="KamaccPage">
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../asstes/images/kampusBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Uniting Your <br/>Institution</h5>
                        <h1>KSMACC</h1>
                        <h5 className='tbheadFive tbHeadRight'>A Intra-Institution <br/>Communication Software</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='ProIntroSec kamaccIntroSec'>
            <div className='ProContSec ktbkIntroC'>
                <Container>
                    <div className='textBlack tbTitle'>
                        <h2 data-aos="fade-down"  data-aos-duration="1000">Introducing <span>KSMACC</span></h2>
                        <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">A State-of-the-Art, All-in-One Closed<br/> Communication Platform</h4>
                        <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">Exclusively designed for Private Groups and Enterprises consisting of <br/>Organizations, Institutions, and Government bodies. Experience Advanced <br/>Integration of Messaging, Calling, News, GPS Monitoring, and Planning and <br/>scheduling Activities with Tasks, all within a single application. Enjoy the <br/>continuity of communication with Role-Based communication channels. <br/>Powerful Administrators to ensure Complete Control.</p>

                    </div>
                    <div className='textBlack ktbkList'>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><strong>The Ultimate Closed <br/> Communication Platform</strong><img src={require ('../../../asstes/images/Tkamac-he-Ultimate-CLOSED-icon.svg').default} alt="The Ultimate CLOSED Communication Platform"/></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="400"><strong>All-in-one platform<br/> with multiple patented <br/>Katchup (Messaging) and<br/> Kall (Calling) features </strong><img src={require ('../../../asstes/images/kamac-All-in-one platform-icon.svg').default} alt="All-in-one platform with multiple patented Katchup (Messaging) and Kall (Calling) features"/></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700"><strong>Multiple Communication<br/> Channels for Class-Section<br/> Interaction with <br/>Teachers as Mentors </strong><img src={require ('../../../asstes/images/kamac-Multiple-Communication-icon.svg').default} alt="Multiple Communication Channels for Class-Section Interaction with Teachers as Mentors "/></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600"><strong>End-to-End Encryption<br/> for Transit and<br/> Data at Rest</strong><img src={require ('../../../asstes/images/kamac-End-to-End-Encryption-icon.svg').default} alt="End-to-End Encryption for Transit and Data at Rest"/></li>
                        </ul>
                     </div>
                </Container>
            </div>
        </section>
        <section className='ProAIPowSec'>
            <Container>
                <Row>
                    <Col md={10} className='ProAIPowSCol kamaccAipow'>               
                        <div className='textBlack tbTitle'>
                            <h2 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Unveiling the power of  KSMACC </h2>
                            <h4 data-aos="fade-down"  data-aos-duration="1000">Explore its Cutting-Edge Features Redefining Communication and Collaboration  </h4>
                        </div>
                        <Kamaccpowerlist/>
                        <div className='ProAIPowIMg'><img src={require ('../../../asstes/images/ProAIPowKamaccImg.png')} alt="Katbook's AI-Powered"/></div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='ProRobSolSec KamaccRobSolSec'>
            <div className='RobSolSlides'>
                <div className={`RobSolSlide RSSlideOne ${shadow ? "RSSlideLHide" : "RSSlideLOpen"}`}  >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000">Connecting <br/><span>Minds</span>, <span>Closed</span> & <span>Secure</span> </h2>
                                    <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Embrace seamless collaboration</h5>
                                </div>
                            </Col>
                            <Col md={4} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Unlock-the-Ultimate-icon.svg').default} alt=""/>
                                        <h4>Unlock the Ultimate</h4>
                                        <p>Ultimate CLOSED <br/>Communication platform <br/>for the institution</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Exclusive-channels-icon.svg').default} alt=""/>
                                        <h4>Exclusive channels</h4>
                                        <p>Embrace exclusive channels for the teacher to access class and section-wise communication.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Centralized-control-icon.svg').default} alt=""/>
                                        <h4>Centralized control</h4>
                                        <p>Admins have holistic<br/> control over the communication<br/> channels.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Device-independent-access-icon.svg').default} alt=""/>
                                        <h4>Device independent access</h4>
                                        <p>Enabled on all - Web, IOS and Android.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Data-Protected-icon.svg').default} alt=""/>
                                        <h4>Data Protected</h4>
                                        <p>End to end encrypted. </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KmaccRobSolImg.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to='#' onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='#' className='linkbtn' onClick={() => setShow(true)}><strong>Corporate</strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
                <div className={`RobSolSlide RSSlideTwo ${shadow ? "RSSlideROpen" : "RSSlideRHide"}`} >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000">Unlock <span>Productivity</span> </h2>
                                    <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Seamless Collaboration with Closed Corporate Communication Fostering Confidentiality</h5>
                                </div>
                            </Col>
                            <Col md={4} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Enhanced-Communication-icon.svg').default} alt=""/>
                                        <h4>Enhanced <br/>Communication with<br/> KPOST </h4>
                                        <p>KPOST enables communication of executives with vendors<br/> and other service <br/>providers.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/kall-katchup-icon.svg').default} alt=""/>
                                        <h4>KALL & KATCHUP<br/> Modules</h4>
                                        <p>The exclusive patented features of the KALL & KATCHUP modules support calling and messaging to individuals and to groups.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Dashboards-icon.svg').default} alt=""/>
                                        <h4>Exclusive<br/> Dashboards</h4>
                                        <p>Exclusive dashboards for each category of users like students, teachers, and management staff.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Back-up-icon.svg').default} alt=""/>
                                        <h4>Back-up<br/> Provision</h4>
                                        <p>Back-up provision enables anytime retrieval of information.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Admin-Control-icon.svg').default} alt=""/>
                                        <h4>Admin Control</h4>
                                        <p>KSMACC is completely configurable by the admins.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KmaccRobSolImgOne.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to='#'  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='#' className='linkbtn' onClick={() => setShow(false)}><strong>Institutions</strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
            </div>
        </section>
        <section className='WChoKBSec WChoKmpsSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Why Choose <span>KSMACC?</span></h2>
                    <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Empowering Communication, Enhancing <br/>Collaboration </h4>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Empowering connections to enhance efficiency. </p>
                </div>
                <div className='WCHooTab'>
                    <KamaccTabs/>
                </div>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section> 
        <Testimonial/>   
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} /> 
    </div>
  )
}



export default Kamacc