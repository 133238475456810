import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Col,  Row, } from 'react-bootstrap';

class Katbookpowerlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleContent() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;

    return (
        <>
        <div className={`Tcontainer ${isOpen ? 'open' : ''}`}>        
            <Row>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Universal Coverage</Accordion.Header>
                            <Accordion.Body>
                                Comprehensive syllabus in alignment with Government/Board-prescribed content.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Enhanced Insights</Accordion.Header>
                            <Accordion.Body>
                                Quality Additional Content for Every Subject, Topic, and Keyword.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Comprehensive Session Split</Accordion.Header>
                            <Accordion.Body>
                                Each session is divided into 3-4 parts, enabling students to access concept explanations, teaching videos, and receive doubt clarification. The Session can be Optimized for Maximum Engagement.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Simplified Learning</Accordion.Header>
                            <Accordion.Body>
                                Concept Videos Demystifying Complex Concepts.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Expert-Led Learning</Accordion.Header>
                            <Accordion.Body>
                                On-Demand Teaching Videos for Flexible and Effective Learning.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Session FAQs</Accordion.Header>
                            <Accordion.Body>
                                Frequently asked questions are enabled for each session.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Student Self-Assessment</Accordion.Header>
                            <Accordion.Body>
                                Assess Your Progress with KATBOOK chapter wise.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Flexible  Highlighting</Accordion.Header>
                            <Accordion.Body>
                                Students Can Mark Important Points in KATBOOK for Future Reference.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Seamless Navigation</Accordion.Header>
                            <Accordion.Body>
                                Direct Access to Chapters and Sessions from the Time Table.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Exam Preparation Support</Accordion.Header>
                            <Accordion.Body>
                                Session Synopsis to Aid Students for Competitive Exams such as NEET and JEE.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Previous year question papers</Accordion.Header>
                            <Accordion.Body>
                                Previous Year Question Papers with Answer Keys Included.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Choice of Platform</Accordion.Header>
                            <Accordion.Body>
                                Access Anywhere with On-Premise or Cloud Solutions.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Inclusive Access for All</Accordion.Header>
                            <Accordion.Body>
                                Empowering Students, Teachers, Institution Administrators, and Parents.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="14">
                            <Accordion.Header>Cross-Platform Versatility</Accordion.Header>
                            <Accordion.Body>
                                Seamless Learning on Web, Android, iOS, and Various Devices.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>Seamless Teaching & Learning</Accordion.Header>
                            <Accordion.Body>
                                Empowering Institutions and Students with World-Class on-campus, off-campus, Hybrid Teaching.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="16">
                            <Accordion.Header>Empowering Institutions</Accordion.Header>
                            <Accordion.Body>
                                A Self-Contained Authoring Tool for Customized Books.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="17">
                            <Accordion.Header>Unleashing Potential</Accordion.Header>
                            <Accordion.Body>
                                A Scalable, High-Quality Platform for Dynamic Growth.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="18">
                            <Accordion.Header>Multiple Delivery Channels</Accordion.Header>
                            <Accordion.Body>
                                Students can get the KATBOOK from the KATBOOK Store for their individual use and also can get it from their Institution using KAMPUS and all its features like Dynamic table.                            
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Inclusive Access</Accordion.Header>
                            <Accordion.Body>
                                Explore All Class Subjects with Ease.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Learning Beyond Boundaries</Accordion.Header>
                            <Accordion.Body>
                                Accessible Offline Mode for Students with challenges with internet connectivity.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Clarification Assistance</Accordion.Header>
                            <Accordion.Body>
                                "Clarify" from any part of the KATBOOK/ learning material for student Inquiries. All Class students benefit from Individual Questions and students can Edit/Delete their questions until commented/replied to. Only the Teacher can answer the clarification raised. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Enhanced Material Sharing</Accordion.Header>
                            <Accordion.Body>
                                Students can attach relevant files to share their understanding.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Personalized Digital Notes</Accordion.Header>
                            <Accordion.Body>
                                Students Can Create Their Own Notes Throughout the Session in KATBOOK and can have access to the notes throughout the year.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Language Translation Assistance</Accordion.Header>
                            <Accordion.Body>
                                Students Can Translate KATBOOK Content to Their Preferred Language for Better Comprehension.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Convenient Bookmarking</Accordion.Header>
                            <Accordion.Body>
                                Students Can Create Bookmarks in KATBOOK for Easy Reference at Any Time.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Integrated Dictionary</Accordion.Header>
                            <Accordion.Body>
                                Students Can Refer to Definitions of Foreign Words within KATBOOK.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8 ">
                            <Accordion.Header>Auditory Learning Support</Accordion.Header>
                            <Accordion.Body>
                                Students Can Listen to the Content of KATBOOK Using the Audio Reader Feature.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Unleash Knowledge</Accordion.Header>
                            <Accordion.Body>
                                Empowering Minds with an Open Content Creation Platform.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Empowering Teacher Admins</Accordion.Header>
                            <Accordion.Body>
                                Seamless Collaboration and a teacher can respond in audio or video and also add attachments.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Configurable KSMACC Communication</Accordion.Header>
                            <Accordion.Body>
                                Admin-Controlled Messaging System within KATBOOK, and KAMPUS.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Seamless Integration with Leading Video Conferencing Providers</Accordion.Header>
                            <Accordion.Body>
                                Zoom, Google Meet, MS Teams, BBB, and More, Empowering Institutes with Flexible Online Teaching Options.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="14">
                            <Accordion.Header>Total Control</Accordion.Header>
                            <Accordion.Body>
                                Empowering Institutions with KAMPUS configurability.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>Inclusive Collaboration</Accordion.Header>
                            <Accordion.Body>
                                Parents can view and participate in the doubt clarification discussion.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="16">
                            <Accordion.Header>Tracking the Learning Journey</Accordion.Header>
                            <Accordion.Body>
                                Student Progress Across Chapters and coverage of Session, Book, enhanced content, teaching and concept videos, etc.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="17">
                            <Accordion.Header>Offline KATBOOK</Accordion.Header>
                            <Accordion.Body>
                                Provides all features of KATBOOK other than in-book Clarification for teaching and learning where the internet is a challenge.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="18">
                            <Accordion.Header>Hybrid KATBOOK</Accordion.Header>
                            <Accordion.Body>
                                Provides online KATBOOK for teaching and Offline KATBOOK for study at home
                            </Accordion.Body>
                        </Accordion.Item>
                         
                    </Accordion>
                </Col>
            </Row>
        </div>
        <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div>
        
        </>
    );
  }
}

export default Katbookpowerlist;
