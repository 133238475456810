import React, { useState, useEffect } from "react";

import { Col,  Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}
function Tab({  content }) {
    return (
      <div className="tab-content">
        <>{content}</>
      </div>
    );
  }
export default function KatbookTabs() {
  const [isAccordion, setIsAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsAccordion(window.innerWidth < 768);
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    toggleAccordion();
    window.addEventListener("resize", toggleAccordion);
    return () => {
      window.removeEventListener("resize", toggleAccordion);
    };
  }, []);

  const tabs = [
    { title: "Students", content: ( <Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Students</h4>
                <h5>Shaping Futures: Unleash Your Potential <br/>with Us!</h5>
                <p>KATBOOK empowers students with 360° immersive learning through <br/>additional content on identified keywords, concept videos, <br/>teaching videos, and unlocking complete knowledge with<br/> simplified explanations. Its anytime accessibility and doubt-clearing<br/> mechanisms build confidence, while collaborative learning fosters a<br/> classroom-like experience anywhere. With KATBOOK, students <br/>accelerate their progress, advance ahead, and unlock fine knowledge.<br/> Students can access the Online classes or Hybrid Classes from <br/>the Dynamic timetable directly in a single Click.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Student-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    { title: "Teachers", content: (<Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Teachers</h4>
                <h5>Empowering Teachers to Inspire, Innovate,<br/> and Ignite Minds</h5>
                <p>KATBOOK revolutionizes teaching by empowering educators <br/>with a plethora of benefits. From session-wise lesson mapping,<br/> and enhanced content creation to real-time progress tracking of<br/> every student, efficient doubt clarification available throughout the <br/>year, and gain more time for preparation, teachers experience <br/>newfound efficiency and effectiveness in their teaching. Teachers<br/> can start Online and Hybrid Classes for the students from the <br/>Dynamic Timetable itself. With KATBOOK, teachers can focus<br/> on what truly matters—inspiring and guiding students to reach <br/>their full potential.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/WCKB-StudImg.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    { title: "Parents", content: (<Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Parents</h4>
                <h5>Real-Time Progress; Ensuring <br/>Parental Assurance</h5>
                <p>KATBOOK offers parents peace of mind by providing their <br/>children with a comprehensive and effective learning platform.<br/> With enhanced content, 24/7 doubt-clearing mechanisms, and<br/> real-time progress reports, KATBOOK ensures their child's academic <br/>progress and boosts confidence. The collaborative learning environment<br/>allows parents to add value to the in-book discussions. It fosters <br/>independent learning to students, giving parents a cost-effective<br/> solution for their child's education.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Parents-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    { title: "Institutions", content: (<Row>
        <Col ms="6">
            <div className='WCL-Cont'>
                <h4>Benefits for Institutions</h4>
                <h5>Seamlessly Transforming Learning for<br/> Tomorrow's World.</h5>
                <p>KATBOOK offers institutions a plethora of benefits to enhance <br/>their educational ecosystem. With equitable access to education, <br/>irrespective of faculty availability, all classes benefit. KATBOOK tracks <br/>student progress, interactions, and involvement, generating aggregate<br/> reports class-wise and subject-wise. This comprehensive approach<br/> ensures a fair and inclusive learning experience for every student.</p>
                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
            </div>
        </Col>
        <Col ms="6">
            <div className='WCL-Img'>
                <img src={require ('../../../asstes/images/katbook-Institution-img.png')} alt="Benefits for Teachers"/>
            </div>
        </Col>
    </Row>), },
    // Add more tabs as needed
  ];

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <ul className="responsive-tabs">
            {tabs.map((tab, index) => (
                <li
                key={index}
                className={activeTab === index ? "active" : ""}
                onClick={() => toggleTab(index)}
                >
                <h4><span>{tab.title}</span></h4>
                </li>
            ))}
            </ul>   
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>         
        </>
      )}
    </div>
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} />
        </>
  );
}
