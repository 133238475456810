import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Kampuspowerlist from './Kampuspowerlist';
import Testimonial from '../../../Components/UEFoot/Testimonial';
import KampusTabs from './KampusTabs';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';
import TalktoExpert from '../TalktoExpert';
import BookaDemo from '../BookaDemo';

const Kampus = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [shadow, setShow] = React.useState(); 
  return (
    <div className="KampusPage">
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../asstes/images/kampusBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Efficiency Meets <br/>Education</h5>
                        <h1>KAMPUS</h1>
                        <h5 className='tbheadFive tbHeadRight'>A Complete Institution <br/>Management Solution</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='ProIntroSec'>
            <div className='ProContSec ktbkIntroC'>
                <Container>
                    <div className='textBlack tbTitle'>
                        <h2 data-aos="fade-down"  data-aos-duration="1000">Introducing <span>KAMPUS</span></h2>
                        <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">the cutting-edge Educational Institution <br/>Management Software that streamlines and <br/>elevates teaching processes</h4>
                        <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">It combines Educational Institution Management System, Teaching & Learning <br/>Management System and Campus Management System, providing a comprehensive <br/>solution for educational institutions' management needs on any campus and <br/>focusing on what truly matters – providing exceptional education. From dynamic <br/>timetables that adapt to changing schedules to students and staff data <br/>management, KAMPUS ensures seamless operations. Experience <br/>a new level of Efficiency!</p>
                    </div>
                    <div className='textBlack ktbkList'>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/><strong>Multicampus <br/>Operation</strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="400"><img src={require ('../../../asstes/images/Multi-Curriculum-icon.svg').default} alt="Multi Curriculum/ affiliations"/><strong>Multi Curriculum/ <br/>affiliations </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="500"><img src={require ('../../../asstes/images/Multisection-icon.svg').default} alt="Multisection/ mediums"/><strong>Multisection/<br/> mediums</strong></li>
                        </ul>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700"><img src={require ('../../../asstes/images/Dynamic-Curriculum-icon.svg').default} alt="Dynamic Curriculum setup"/><strong>Dynamic <br/>Curriculum setup </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600"><img src={require ('../../../asstes/images/Efficient-Backend-icon.svg').default} alt="Efficient Backend for Access to KATBOOKS"/><strong>Efficient Backend<br/> for Access<br/> to KATBOOKs</strong></li>
                        </ul>
                     </div>
                </Container>
            </div>
        </section>
        <section className='ProAIPowSec'>
            <Container>
                <Row>
                    <Col md={10} className='ProAIPowSCol kampusAipow'>               
                        <div className='textBlack tbTitle'>
                            <h2 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">KAMPUS Highlights </h2>
                            <h4 data-aos="fade-down"  data-aos-duration="1000">Dynamic Features for Education Management   </h4>
                        </div>
                        <Kampuspowerlist/>
                        <div className='ProAIPowIMg'><img src={require ('../../../asstes/images/ProAIPowKampusImg.png')} alt="Katbook's AI-Powered"/></div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='ProRobSolSec KampusRobSolSec'>
            <div className='RobSolSlides'>
                <div className={`RobSolSlide RSSlideOne ${shadow ? "RSSlideLHide" : "RSSlideLOpen"}`}  >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000">Optimize <span>KAMPUS</span> Experience </h2>
                                    <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Streamline Education and Enhance Collaboration for <br/> Seamless Learning </h5>
                                </div>
                            </Col>
                            <Col md={5} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Accessibility-icon.svg').default} alt=""/>
                                        <h4>Accessibility</h4>
                                        <p>KAMPUS is accessible <br/>On-Site or via the <br/>Cloud Platform.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Personalized-icon.svg').default} alt=""/>
                                        <h4>Personalized <br/>Access</h4>
                                        <p>Unlock Your Institution's <br/>Potential with Exclusive <br/>Sub-Domains.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Flexible-Hosting-icon.svg').default} alt=""/>
                                        <h4>Flexible Hosting <br/>Options</h4>
                                        <p>Empowering Institutions <br/>with On-Premise or <br/>KAMPUS Managed <br/>Cloud Servers.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Seamless-Integration-icon.svg').default} alt=""/>
                                        <h4>Seamless <br/>Integration</h4>
                                        <p>Enhance Efficiency <br/>with Easy ERP <br/>Integration for Your <br/>Institution.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KpusRobSolImg.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                            <Col md={12} className='ProRobBtmcol'>  
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Empowering-with-Exclusive-icon.svg').default} alt=""/>
                                        <h4>Empowering with Exclusive Dashboards</h4>
                                        <p>Admins, Teachers, and Students – Tailored <br/>Access for Enhanced Efficiency.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Revolutionizing-Communication-icon.svg').default} alt=""/>
                                        <h4>Revolutionizing Communication</h4>
                                        <p>World's First Permanent, Transferable <br/>Role-Based Channels for <br/>Educational Staff.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KAMPUS-for-all-icon.svg').default} alt=""/>
                                        <h4>KAMPUS for All</h4>
                                        <p>KAMPUS is the right solution for Schools, <br/>Higher Education, Tutorials and <br/>academies, and corporate training.</p>
                                    </div>
                                </div>
                            </Col >
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='/' className='linkbtn' onClick={() => setShow(true)}><strong>Corporate</strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
                <div className={`RobSolSlide RSSlideTwo ${shadow ? "RSSlideROpen" : "RSSlideRHide"}`} >
                <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000">KAMPUS </h2>
                                    <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Boost your corporate training efficiency with a comprehensive solution </h5>
                                </div>
                            </Col>
                            <Col md={5} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Empowering-Corporate-Learning-icon.svg').default} alt=""/>
                                        <h4>Empowering <br/>Corporate Learning</h4>
                                        <p>Embrace On-Campus, <br/>Off-Campus, and <br/>Hybrid Teaching with Ease.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Empower-Your-Team-icon.svg').default} alt=""/>
                                        <h4>Empower Your Team <br/>with Role-Based<br/> Dashboards</h4>
                                        <p>Gain Complete Control at Your Fingertips.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Unlocking-Corporate-Efficiency-icon.svg').default} alt=""/>
                                        <h4>Unlocking<br/> Corporate Efficiency</h4>
                                        <p> Our solution is 100% <br/>customizable and <br/>seamlessly integrates<br/> with any ERP,  tailored<br/> for corporate efficiency.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Experience-Next-Gen-icon.svg').default} alt=""/>
                                        <h4>Experience Next-Gen<br/> Collaborative Learning</h4>
                                        <p>Unleash the Power <br/>of Dynamic Timetables<br/> for Corporates.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/KpusRobSolImgOne.jpg')} alt="Robust Solutions "/> 
                                <p className='exprbtn'><Link to="#"  onClick={() => setModalShow(true)} className=''>enquire now</Link></p>
                            </Col >
                            <Col md={8} className='ProRobBtmcol'>  
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Streamline-Corporate-Training-icon.svg').default} alt=""/>
                                        <h4>Streamline<br/> Corporate Training</h4>
                                        <p>Effortlessly Track Attendance, Assignments,<br/> Online Exams, Results and More.</p>
                                    </div>
                                </div>
                            </Col >
                        </Row>
                    </Container>
                    <div className='RobSolSlideLink'><button to='#' className='linkbtn' onClick={() => setShow(false)}><strong>Institutions</strong> <img src={require ('../../../asstes/images/LineArrowRight.svg').default} alt=""/></button></div>
                </div>
            </div>
        </section>
        <section className='WChoKBSec WChoKmpsSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Why Choose <span>KAMPUS?</span></h2>
                    <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Unveiling the Advantages of Seamless <br/>Education Management</h4>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Achieving Seamless Collaboration and Transformative Benefits with KAMPUS.</p>
                </div>
                <div className='WCHooTab'>
                    <KampusTabs/>
                </div>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section> 
        <Testimonial/>  
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} /> 
    </div>
  )
}



export default Kampus