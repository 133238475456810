import React from 'react'
import { Col, Container, Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF,FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";
 import  './contact.css'
 import ContactusForm from './ContactusForm';

const Contactus = () => {
  return (
    <div className="contactPage">
        
     <section className='InnerPBanSec'>
     <div className='IPBBgimg'>
                <img src={require ('../../asstes/images/contactBanBg.jpg')} alt=""/>
            </div>
             <div className='BanContSec'>
                <Container>
                <Row>
                <Col md={4} data-aos="fade-down" data-aos-duration="1000">
                  <h1>Get in
touch</h1>
<p>Your Questions, Our Expertise  <br/>
Let's Shape Education Together.</p>
                  </Col>

                  <Col md={8} data-aos="fade-up" data-aos-duration="1000">
                  <Row>
                  <Col md={6}>
                 <div className='cBox'>
                 <div className='Icon'><img src={require ('../../asstes/images/phone-icon.png')} alt=""/></div>
                 <div className='Text'><strong>Phone</strong>
                 <Link to='tel:919884800819'> +91 98848 00819 </Link></div>
                  </div> 

 
                  <div className='cBox'>
                  <div className='Icon'><img src={require ('../../asstes/images/follow-icon.png')} alt=""/></div>
                 
                  <div className='Text'> <strong>Follow Us</strong>
                  <ul className='smediaul'>
                        <li><Link to="/"><FaFacebookF /></Link></li>
                        <li><Link to="/"><FaLinkedinIn/></Link></li>

                        {/* <li><Link to="/"><FaTwitter /></Link></li>
                        <li><Link to="/"><FaInstagram /></Link></li>
                        <li><Link to="/"><FaYoutube /></Link></li> */}
                    </ul>
                    </div> 
                  </div> 
                  </Col>
                  <Col md={6}>
                  <div className='cBox'>
                  <div className='Icon'><img src={require ('../../asstes/images/mail-icon.png')} alt=""/></div>
                  <div className='Text'>  <strong>Mail</strong>
                  <Link to='mailto:contact@kp.ueducate.in'>contact@kp.ueducate.in</Link>
                  </div> 
                  </div> 
                  </Col>
                  </Row>


                  </Col>

</Row>

                 </Container>
            </div>
        </section>


        <section className='contactFormSec'>

        <div className='RightImg'>
                <img src={require ('../../asstes/images/contact-right-img.png')} alt=""/>
            </div>

              <div className='ProContSec' data-aos="fade-down" data-aos-duration="1000">
        <Container>
           
 <Col md={8}>
 <div className='contactFormTop'>
 <h2>Start a Conversation</h2>
<p>Your Journey to Educational Excellence Begins with Us
</p>
</div>
<ContactusForm/>
        

                                </Col>
            </Container>
            </div>

            </section>

    </div>
  )
}

export default Contactus