import React from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import OurSolutionsTabs from './OurSolutionsTabs';
import OSParntTabs from './OSParntTabs';
import OSTeachTabs from './OSTeachTabs';
import OSInstituTabs from './OSInstituTabs';
import TalktoExpert from '../Products/TalktoExpert';
import BookaDemo from '../Products/BookaDemo';

const OurSolutions = () => {
  return (
    <div className='SolutionsPage'>
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../asstes/images/katbookBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Empower Learning <br/>through Innovative</h5>
                        <h1>Solutions</h1>
                        <h5 className='tbheadFive tbHeadRight'>for Shaping <br/>the Future of Learning</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='OsoluIntroSec'>
            <Container>
                <Row>
                    <Col md='8'>
                        <div className='textBlack tbTitle'>
                            <h2 data-aos="fade-down"  data-aos-duration="1000">Unveiling Our<br/>Transformative <br/><span>Solutions</span></h2>
                            <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Redefining Education for a <br/>Future of Possibilities and Growth.</h4>
                            <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">	At UEDUCATE, we revolutionize education through our comprehensive solutions – KATBOOK, KAMPUS, KSMACC, and USTUDY. KATBOOK redefines collaborative learning, fostering engagement and knowledge sharing among educators and students. KAMPUS offers a seamless virtual classroom experience and has a dynamic time table for easy navigation to the chapters directly. </p>
                            <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300">KSMACC facilitates effortless communication through its patented features in KALL and KATCHUP modules for collaboration among all stakeholders of the institution. USTUDY pioneers a personalized, boundary-less learning journey, catering to all, enabling continuous corporate training and skill enhancement. Together, these solutions create an ecosystem where learning & teaching converge, harnessing technology to cultivate a dynamic, inclusive, and effective educational landscape that empowers both educators and learners for a brighter future.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='OsoluDetlSec OsoluStudntSec'>
            <Container>
                <Row>
                    <Col md='8'>
                        <div className='textBlack tbTitle titlemBtm' >
                            <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">For</h5>
                            <h2 data-aos="fade-down"  data-aos-duration="1000">Students</h2>
                        </div>
                        <OurSolutionsTabs/>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='OsoluDetlSec OsoluParntSec'>
            <Container>
                <Row>
                    <Col md='8'>
                        <div className='textBlack tbTitle titlemBtm' >
                            <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">For</h5>
                            <h2 data-aos="fade-down"  data-aos-duration="1000">Parents</h2>
                        </div>
                        <OSParntTabs/>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='OsoluDetlSec OsoluTeachSec'>
            <Container>
                <Row>
                    <Col md='8'>
                        <div className='textBlack tbTitle titlemBtm' >
                            <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">For</h5>
                            <h2 data-aos="fade-down"  data-aos-duration="1000">Teacher</h2>
                        </div>
                        <OSTeachTabs/>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='OsoluDetlSec OsoluInstituSec'>
            <Container>
                <Row>
                    <Col md='8'>
                        <div className='textBlack tbTitle titlemBtm' >
                            <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">For</h5>
                            <h2 data-aos="fade-down"  data-aos-duration="1000">Institution</h2>
                        </div>
                        <OSInstituTabs/>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='OSAccesibSec'>
            <Container>
                <div className='textBlack tbTitle titlemBtm' >
                    <h2 data-aos="fade-down"  data-aos-duration="1000"><span>Accessibility</span></h2>
                    <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Education Without Boundaries, <br/>Through UEDUCATE. </h4>
                </div>
                <div className='AccesiItems'>
                    <Row>
                        <Col md='4'>
                            <div className='AccesiItem ConLeft' >
                                <div className='AccesiImg' >
                                    <img src={require("../../asstes/images/OSAccesImg1.jpg")} alt='ONLINE '/>
                                    <h3 data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="100">ONLINE </h3>
                                </div>
                                <div className='AccesiCont' >
                                    <p data-aos="fade-right"  data-aos-duration="1000" data-aos-delay="500">Discover the power of learning with UEDUCATE's online accessibility. Whether it's KATBOOK, KAMPUS, or KSMACC, access a world of knowledge, collaboration, and growth whenever you're connected to the internet. Elevate your learning journey anytime, anywhere.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md='4'>
                            <div className='AccesiItem ConLeft' >
                                <div className='AccesiImg' >
                                    <img src={require("../../asstes/images/OSAccesImg2.jpg")} alt='FULL HYBRID'/>
                                    <h3 data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="200">FULL HYBRID</h3>
                                </div>
                                <div className='AccesiCont' >
                                    <p data-aos="fade-right"  data-aos-duration="1000" data-aos-delay="600">Experience the revolutionary Full Hybrid feature, setting new standards. Download all content, videos included, to your device. Enjoy unparalleled 24x7 access to top-tier educational materials, enriching your learning journey like never before.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md='4'>
                            <div className='AccesiItem  ConLeft' >
                                <div className='AccesiImg' >
                                    <img src={require("../../asstes/images/OSAccesImg3.jpg")} alt='OFFLINE'/>
                                    <h3 data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="300">OFFLINE</h3>
                                </div>
                                <div className='AccesiCont' >
                                    <p data-aos="fade-left"  data-aos-duration="1000" data-aos-delay="700">Enjoy uninterrupted access to KATBOOK, KAMPUS, and KSMACC even without an internet connection. Experience round-the-clock access, even in remote areas without connectivity. Access base content, annotate, and use features offline. Your contributions sync when online.</p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md='6'>
                            <div className='AccesiItem ColRevers ConRight' >
                                <div className='AccesiImg' >
                                    <img src={require("../../asstes/images/OSAccesImg4.jpg")} alt='PARTIAL HYBRID'/>
                                    <h3 data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="400">PARTIAL HYBRID</h3>
                                </div>
                                <div className='AccesiCont' >
                                    <p data-aos="fade-left"  data-aos-duration="1000" data-aos-delay="800">With Partial Hybrid, seize control to download vital supplementary content on your device, ensuring no missed opportunities. Preserve device space while accessing quality KATBOOK content seamlessly, striking the perfect balance.</p>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>   
    </div>
  )
}

export default OurSolutions