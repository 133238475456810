import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Ustudyhigslide from './Ustudyhigslide';
import Testimonial from '../../../Components/UEFoot/Testimonial';
import UstudyTabs from './UstudyTabs';
import EnquirePopupFrom from '../../../Components/EnquirePopupFrom';
import TalktoExpert from '../TalktoExpert';
import BookaDemo from '../BookaDemo';
import  './ustudy.css';

const Ustudy = () => {
    const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="ustudyPage">
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../asstes/images/ustudyBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Bridging learning  <br/>beyond borders through</h5>
                        <h1>Ustudy</h1>
                        <h5 className='tbheadFive tbHeadRight'>A Global E-Learning Solutions for  Collaborative Experience</h5>
                    </div>
                </Container>
            </div>
        </section>
        <section className='ProIntroSec'>
            <div className='ProContSec UstdyIntroC'>
                <Container>
                    <div className='textBlack tbTitle'>
                        <h2 data-aos="fade-down"  data-aos-duration="1000">Introducing <span>Ustudy</span></h2>
                        <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">An e-learning platform that facilitates a collaborative</h4>
                        <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">teaching and learning experience, leading to a personalized learning journey. Engineered to revolutionize the learning <br/>
platform, through empowering individuals on their unique learning journey. As a pioneer we take a new approach to transform <br/>
education, making it universally accessible and of uncompromised quality. USTUDY empowers individuals to curate their <br/>
educational path, aligning courses with their passions. Geared towards an inventive work-study approach, <br/>
enriching academic learning through practical engagement. Collaborative alliances between <br/>
educational institutions and businesses furnish students with invaluable <br/>
educational experience.</p>
                    </div>
                    <div className='textBlack ktbkList'>
                        <ul>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/customizablelearningpaths.svg').default} alt="customizable learning paths"/><strong>Customizable 
 <br/> Learning Paths </strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="400"><img src={require ('../../../asstes/images/ExpertDrivenResources.svg').default} alt="Expert Driven Resources"/><strong>Expert-Driven 
  <br/> Resources</strong></li>
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="500"><img src={require ('../../../asstes/images/DynamicTimetabling.svg').default} alt="Dynamic Timetabling"/><strong>Dynamic 
<br/> Timetabling</strong></li>
<li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700"><img src={require ('../../../asstes/images/ComprehensiveE-LearningModule.svg').default} alt="Comprehensive E-Learning Module"/><strong>Comprehensive 

<br/> E-Learning Module</strong></li>
                        </ul>
                        <ul>
                           
                            <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600"><img src={require ('../../../asstes/images/GlobalLearningCommunity.svg').default} alt="Global Learning
Community"/><strong>Global Learning 
<br/> Community</strong></li>
                        <li data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600"><img src={require ('../../../asstes/images/WorkStudymodel.svg').default} alt="Work-Study model"/><strong>Work-Study<br/> model</strong></li>
                        </ul>
                     </div>
                </Container>
            </div>
        </section>
        <section className='ProAIPowSec ustudyHigl'>
            <Container>
                <Row>
                <Col md={5} className='ProAIPowSCol ustudyHiglLeft'> 
                <div className='ProAIPowIMg'><img src={require ('../../../asstes/images/ustudy-w-img.png')} alt="Katbook's AI-Powered"/></div> 
                </Col>

                    <Col md={7} className='ProAIPowSCol ustudyHiglRight'>               
                        <div className='textBlack tbTitle'>
                            <h2 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Ustudy Highlights </h2>
                            <h4 data-aos="fade-down"  data-aos-duration="1000">Hand-on Features for an Online <br/>
Learning Experience   </h4>
<p data-aos="fade-down"  data-aos-duration="1000">USTUDY comes equipped with an assortment of features that facilitates 
an enhanced education experience.</p>
                        </div>
                        <Ustudyhigslide/>
                       
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='ProRobSolSec KampusRobSolSec ustudyRed'>
            <div className='RobSolSlides'>
                <div className={`RobSolSlide RSSlideOne`}  >
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className='textBlack tbTitle titlemBtm'>
                                    <h2 data-aos="fade-down"  data-aos-duration="1000"><span>USTUDY</span> for Corporates Redefines Learning to the Industry’s Standard  </h2>
                                    <h5 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Bespoke Learning to Elevate Workforce Excellence </h5>
                                </div>
                            </Col>
                            <Col md={5} className='ProRobRcol'>
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/EfficientDataManagement.svg').default} alt="Efficient Data Management"/>
                                        <h4>Efficient Data <br/>Management</h4>
                                        <p>Streamline tasks & <br/> manage  employee <br/>training data.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/SeamlessERPIntegration.svg').default} alt="Seamless ERP Integration"/>
                                        <h4>Seamless ERP <br/>Integration </h4>
                                        <p>Training aligns with <br/> organizational <br/> processes.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/SecureCommunication.svg').default} alt="Secure Communication"/>
                                        <h4>Secure <br/> Communication <br/> Channels</h4>
                                        <p>Collaboration with <br/> trainers, management, <br/> and learners.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/Role-BasedDashboards.svg').default} alt="Role-Based Dashboards"/>
                                        <h4>Role-Based <br/> Dashboards</h4>
                                        <p>Tailored access to <br/> different groups for <br/> management.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7} className='ProRobLcol'>  
                                <img src={require ('../../../asstes/images/Ustudy-Redefines-img.jpg')} alt="Robust Solutions "/> 
                                
                            </Col >
                            <Col md={12} className='ProRobBtmcol'>  
                                <div className='RobSolItems'>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/ExpertWebinars.svg').default} alt="Expert Webinars"/>
                                        <h4>Expert <br/>  Webinars</h4>
                                        <p>Industry-specific <br/> insights from experts.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/CustomizableLearningPath.svg').default} alt="Customizable Learning Path"/>
                                        <h4>Customizable  <br/> Learning Paths</h4>
                                        <p>Designed programs for <br/> employee development.</p>
                                    </div>
                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/TimeProgressTracking.svg').default} alt="Real-Time Progress Tracking"/>
                                        <h4>Real-Time Progress <br/> Tracking</h4>
                                        <p>Monitoring for targeted <br/> training interventions.</p>
                                    </div>

                                    <div className='RobSolItem'>
                                        <img src={require ('../../../asstes/images/KBRobSolIcon10.svg').default} alt="Flexible Learning Environments"/>
                                        <h4>Flexible Learning <br/> Environments</h4>
                                        <p>Adapt to flexibility modern  <br/> workplaces demand.</p>
                                    </div>
                                </div>
                            </Col >
                        </Row>
                    </Container>
                 </div>
                 
            </div>
        </section>
        <section className='ProApplinkSec'>
            <Container>
                <div className='textBlack tbTitle'>
                    <h4 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">Get your <br/><span className='colorchange'>K</span>ollaborative <br/><span className='colorchange'>A</span>dvanced <br/><span className='colorchange'>T</span>ext<span className='colorchange'>book</span> NOW!</h4>
                    <ul data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
                        <li><Link to='https://www.katbookstore.com/' target='_blank'><img src={require('../../../asstes/images/digital-katebook-img.png')} alt="digital-katebook-img"/></Link></li>
                        <li><Link to='#'><img src={require ('../../../asstes/images/GPlayImg.webp')} alt="Google Play"/></Link></li>
                        <li><Link to='#'><img src={require ('../../../asstes/images/AppStoreImg.webp')} alt="App Store"/></Link></li>
                    </ul>
                </div>
                <div className='applinkdiv'><img src={require('../../../asstes/images/proAppLinkImg.png')} alt="App Link"/></div>
            </Container>
        </section>

         

        <section className='WChoKBSec WChoKmpsSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Why <span>USTUDY</span> is a </h2>
                    <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">better option? Empowering Your Workforce</h4>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Creating a culture of learning and development means providing employees with<br/> the opportunity to learn new skills and knowledge.</p>
                </div>
                <div className='WCHooTab'>
                    <UstudyTabs/>
                </div>
            </Container>
        </section>
        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section> 
        <Testimonial/>  
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} /> 
    </div>
  )
}



export default Ustudy