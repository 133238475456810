import React, { Component } from "react";
import Slider from "react-slick";

export default class MultipleRows extends Component {
  render() {
    const settings = {
        infinite: false,
        slidesToShow:1,
        speed: 500,
        rows: 5,
        Padding: '10px',
        slidesPerRow: 2,
        responsive: [
                {
                        breakpoint: 551,
                        settings: {                                
                                slidesPerRow: 1,
                        },
                },
        ],
    };
    return (
      <div>

        <Slider {...settings}>
          <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/EngagingLearningModules-01.svg').default} alt="Engaging Learning Modules"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>

  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/RealTimeProgress.svg').default} alt="RealTime rogress"/>
          <strong>Real-Time Progress 
<br/>Tracking  </strong></div>
  </div>

  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/DynamicAssessmentTools-01.svg').default} alt="Dynamic Assessment RTools"/>
          <strong>Dynamic 
<br/>Assessment Tools </strong></div>
  </div>

         
  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/CollaborativeLearningSpaces.svg').default} alt="Collaborative Learning Spaces"/>
          <strong>Collaborative 
<br/>Learning Spaces  </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/ResourceRichlibrary-01.svg').default} alt="Resource-Rich 
<br/>Library"/>
          <strong>Resource-Rich 
<br/>Library </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/IntraInstitutionalcommunication.svg').default} alt="Intra Institutional communication"/>
          <strong>Intra Institutional  
 <br/>Communication </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/247Support-01.svg').default} alt="Multicampus Operation"/>
          <strong>24/7 Support</strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/costeffectiveness.svg').default} alt="cost effectiveness"/>
          <strong>Cost
 <br/>effectiveness </strong></div>
  </div>


  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/SmartphoneComputerAppAccess.svg').default} alt="Smartphone / Computer App Access"/>
          <strong>Smartphone/ Computer  
 <br/>Tablet App Access </strong></div>
  </div>


  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/CareerGuidance.svg').default} alt="Career Guidance"/>
          <strong>Career 
 <br/>Guidance </strong></div>
  </div>


  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/on-jobtraining.svg').default} alt="on-job training"/>
            <strong>On-job Training </strong></div>
  </div>

    {/*<div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>

 <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>

         
  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>



  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>


  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div>


  <div>
          <div className="iconBox" data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><img src={require ('../../../asstes/images/Multicampus-icon.svg').default} alt="Multicampus Operation"/>
          <strong>Engaging Learning 
 <br/>Modules </strong></div>
  </div> */}

        </Slider>
      </div>
    );
  }
}