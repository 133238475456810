import React from 'react';
import { Col, Container, Row} from 'react-bootstrap';
 import  './aboutus.css';
 import Milestone from './Milestone';
 

const Aboutus = () => {
 
  return (
    <div className='AboutPage'>
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../asstes/images/aboutBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h5 className='textright tbheadFive tbHeadLeft'>Discover the <br/> excellence at UEDUCATE's</h5>
                        <h1>About us</h1>
                        <h5 className='tbheadFive tbHeadRight'>Nurturing Minds, <br/> Inspiring Futures</h5>
                    </div>
                </Container>
            </div>
        </section>

        <section className='aboutIntrosec'>
        <Container>
      
<div className='textBlack tbTitle'>
 
   <h2 data-aos="fade-down"  data-aos-duration="1000">Nurturing <span>Minds</span></h2>
                        <h4 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Where Knowledge Meets Innovation <br/>
for a Brighter Future</h4>
                         </div>
 
<p>UEDUCATE is a pioneering educational platform with a history dating back to 1961, offering <br/>
exceptional consulting services before venturing into the education sector. With our patented digital ecosystem, <br/>
UEDUCATE, targets making education accessible and equitable for all individuals. Provide advanced, <br/>
collaborative, and interactive learning experiences for learners worldwide By collaborating with publishers, <br/>
institutes, universities, governments, and educators, we strive to elevate the quality of education. <br/>
Our platform breaks traditional boundaries, offering an engaging and interactive learning experience <br/>
for students, teachers, and educational institutions.
</p>
</Container>
        </section>

        <section className='aboutJourneyTop'>
        <div className='IPBBgimg'>
                <img src={require ('../../asstes/images/about-journey-img.jpg')} alt=""/>
            </div>
        </section>


        <section className='aboutJourneyBottom'>
        <Container>
        <div className='textBlack tbTitle'>
         <h2  title="Our Journey" data-aos="fade-down"  data-aos-duration="1000">Our Journey</h2>
         <p data-aos="fade-down"  data-aos-duration="1000">A Pathway of discovery, growth, and success</p>
         </div>

         <Milestone/>

          
        </Container>
        </section>


        <section className='mdmessage'>
        <Container>
<Row>
<Col md={4} data-aos="fade-up" data-aos-duration="1000" className='mdmessageLeft'> <img src={require ('../../asstes/images/chairman-img.png')} alt=""/> </Col>
<Col md={8} data-aos="fade-down" data-aos-duration="1000" className='mdmessageRight'>
<div className='textBlack tbTitle'>
         <h5  title="Our Journey" >About our Leader  </h5>
         <h2>Mr. SARATH <span> KAKUMANU</span></h2>
<p>Mr. Sarath Kakumanu is the visionary behind the Pioneer Group. He holds a bachelor's in architecture from the University of Madras and pursued architecture and urban planning in the USA. With over five decades of experience, he founded Pioneer, a prominent real estate company that crafts 3 million square feet of premium properties and revolutionizes construction with KWALL panels. Later, he navigated his software journey, marking milestones like KAMS, KPOST, KSMACC, and UEDUCATE, addressing various sectors for nearly three decades.</p>
<p>His latest milestone, UEDUCATE, creates a platform for accessible and affordable education through KAMPUS, KATBOOK, KSMACC, and USTUDY. With a portfolio that boasts groundbreaking creations like FAMS, REMSS, IPAMS, SWAMS, DDMS, PAMS, Zilding, and Zucort, he has redefined construction and software in his own innovative way.</p>
         </div>

</Col>

</Row>

        </Container>
        </section>
       
    </div>
  )
}

export default Aboutus