import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Col,  Row, } from 'react-bootstrap';

class Kampuspowerlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleContent() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;

    return (
        <>
        <div className={`Tcontainer ${isOpen ? 'open' : ''}`}>        
            <Row>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Personalized Access</Accordion.Header>
                            <Accordion.Body>
                                Unlock Your Institution's Potential with Exclusive Sub-Domains.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Flexible Hosting Options</Accordion.Header>
                            <Accordion.Body>
                                Empowering Institutions with On-Premise or UEDUCATE Managed Cloud Servers.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Seamless Integration</Accordion.Header>
                            <Accordion.Body>
                                Enhance Efficiency with Easy ERP Integration for Your Institution.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Ensuring Secure Communication for All</Accordion.Header>
                            <Accordion.Body>
                                Connecting Parents, Teachers, Staff, and Students Safely.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Empowering with Exclusive Dashboards</Accordion.Header>
                            <Accordion.Body>
                                Admins, Teachers, Students, and Parents – Tailored Access for Enhanced Efficiency.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Efficient Data Management</Accordion.Header>
                            <Accordion.Body>
                                Empowering Educational Excellence through Organized Student, Parent, Teacher, and Admin Records.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Seamless Timetabling</Accordion.Header>
                            <Accordion.Body>
                                Error-Free Digital Schedules for Smooth Academic Operations.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Seamless Session Management</Accordion.Header>
                            <Accordion.Body>
                                Never miss a beat with our intelligent system that automatically reschedules or completes sessions for uninterrupted learning.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Comprehensive E-Learning Module</Accordion.Header>
                            <Accordion.Body>
                                Seamlessly manage attendance, assignments, online exams, and results, all in one user-friendly student dashboard.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>All-in-One Academic Diary</Accordion.Header>
                            <Accordion.Body>
                                Empowering students and staff to plan, prioritize, and conquer tasks, ensuring successful completion on schedule.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Stay Informed, Engage, and Celebrate</Accordion.Header>
                            <Accordion.Body>
                                Explore a dynamic virtual Poster Board and stay updated on exciting school events and announcements.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Seamless Subject Planning</Accordion.Header>
                            <Accordion.Body>
                                Unlocking Knowledge with Clear Chapter and Topic Mapping.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Integrated Dynamic Timetable</Accordion.Header>
                            <Accordion.Body>
                                Join virtual classes effortlessly and access relevant subjects in KATBOOK with ease, ensuring a smooth learning experience.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Customize Your Learning Journey</Accordion.Header>
                            <Accordion.Body>
                                Enhance Private KATBOOK with Additional Content.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Empowering Institutions</Accordion.Header>
                            <Accordion.Body>
                                Full Control over Student Access to KATBOOK.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Enhanced Parental Engagement </Accordion.Header>
                            <Accordion.Body>
                                Facilitates comprehensive parent interaction, offering insights into students' activities such as time spent on base textbook, additional content, concept videos, teaching videos, and participation in clarifications based on queries.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Connecting Education Ecosystem</Accordion.Header>
                            <Accordion.Body>
                                Introducing KSMACC Communication Channels for all stakeholders.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Empowering Secure Educational Communication</Accordion.Header>
                            <Accordion.Body>
                                Total Control Over Internal Closed Channels.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Revolutionizing Communication</Accordion.Header>
                            <Accordion.Body>
                                World's First Permanent, Transferable Role-Based Channels for Educational Staff.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Effortless Teacher Communication</Accordion.Header>
                            <Accordion.Body>
                                Uniting Class, Section, and Subject with Multiple Channels. Suitable for Classroom, Online, and Hybrid modes of teaching with BBB (BigBlueButton) integration.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </div>
        <div className="alllessbtn"><button className="more" onClick={this.toggleContent}> {isOpen ? 'Less View -' : 'View All +'} </button></div>
        </>
    );
  }
}

export default Kampuspowerlist;
