import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import { Container, Row, Col } from 'react-bootstrap';
import Testimonial from '../../Components/UEFoot/Testimonial';


const Home = () => {
  
  useEffect(() => {

    document.body.classList.add('HomePage')
    return () => {
        document.body.classList.remove('HomePage')
    }
    
  }, [])


 
  if (window.matchMedia("(min-width: 801px)").matches) {
    const inViewport = (elem) => {
      let allElements = document.getElementsByTagName(elem);
      let windowHeight = window.innerHeight;
      const elems = () => {
        for (let i = 0; i < allElements.length; i++) {
          let viewportOffset = allElements[i].getBoundingClientRect(); 
          let top = viewportOffset.top;  
          let middle = windowHeight/2;
          if(top <= middle){  
            allElements[i].classList.add('in-viewport'); 
            
          } else{
            allElements[i].classList.remove('in-viewport');
          }
        }
      }
      elems();
      window.addEventListener('scroll', elems);
    }
    
    inViewport('section');
  
  }

  return (
    <div className="HomePage" id='HomepageTop'>
        <section class="section secOne">
          <div className='UeHSecns UeHBanSec'>
            <div className='UeHSecImg UeHBanImg'>
              <img src={require('../../asstes/images/hbanImg.jpg')} alt="U Educated" className='HSecBgs'/>            
              <div className='Hbanbefore'><img fetchpriority="high" src={require('../../asstes/images/hbanPersonImg.webp')} alt="UEducted"/></div></div>
            <div className='UeHBanCont'>
              <h6 className='AnimiOFlow'><span className="dropinAnimi">The</span></h6>
              <h1 className='AnimiOFlow'><span className="dropinAnimi dropinAnimiDelay">Future</span></h1>
              <h3 className='AnimiOFlow'><span className="dropinAnimi dropinAnimiDelay1"><span className='EBig'>E</span><span className='hidE'>E</span>CO-System of<br/> <span className='hidE'>E</span>ducation</span></h3>
              <p className='AnimiOFlow'><span className="dropinAnimi dropinAnimiDelay2">Embrace the digital age and revolutionize <br/>learning with advanced knowledge <br/>discovery methods.</span></p>
            </div>
          </div>  
        </section>
        <section class="section secTwo">
            <div className='UeHSecns UeHIntroSec'>
                <div className='UeHSecImg'><img src={require('../../asstes/images/hIntroSecBg.jpg')} alt="UEducted"  className='HSecBgs'/><span className='staranimi'></span></div>
                <div className='UeHIntroCont'>
                    <h6 data-aos="fade-down" data-aos-duration="1000">Transforming Learning</h6>
                    <h2 className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Our <b>Vision</b> is to create a digital eco-system<br/> which enables education to be accessible, <br/>  equitable and affordable to all.</h2>
                    <p className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200"><Link to={process.env.PUBLIC_URL + '/about'}>explore +</Link></p>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300">UEDUCATE envisions providing a comprehensive platform where institutions find holistic<br/> solutions to their student’s academic and cognitive needs, unified.</p>
                </div>
            </div>
        </section>
        <div className='MobSecsAnimi'>
          <section class="section secThree SecMobAnimi">
              <div className='InSecMobAnimi'>
                <div className='UeHSecns UeHKatbookSec'>
                  <div className='UeHSecImg'>
                    <img src={require('../../asstes/images/hKatbookBgImg.jpg')} alt="UEducted" className='HSecBgs'/>
                    <div className='UeHBeforeImg katbbefore'><img src={require('../../asstes/images/HKatbookImg.webp')} alt="UEducted" data-aos="zoom-out" data-aos-duration="3000"/></div>
                  </div>
                  <div className='HSecConts'>
                    <Container>
                      <div className='HSContBox KatbTitles'>
                        <div className='HProTitles TitleLeftDiv'>
                              <h5 data-aos="fade-down" data-aos-duration="1000">The</h5>
                              <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Future</h2>
                              <h6 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">of education</h6>
                              <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" className='HWebViewCont'>The future of education dwells upon the advancement<br/> of digital tools. Elevate the standard of teaching<br/> and learning through session-wise mapping,<br/> additional content and an in-built tool for <br/>clarification of doubts within the textbook.</p>
                              <p className='HMobViewCont'>Education's future dwells on digital tool progress, enhancing teaching with session mapping, extra content, and textbook embedded clarification buttons.</p>
                        </div>     
                        <div className='HProTitles TitleRightDiv'> 
                              <h3 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600">KATBOOK</h3>
                              <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700" className='HWebViewCont'>KATBOOK, a collaborative advanced digital textbook is<br/> a one-stop solution for all teaching and learning<br/> requirements. Powerful communication features<br/> combined with all required information make<br/> KATBOOK a perfect educational tool that<br/> caters to diverse needs. </p>
                              <p className='HMobViewCont'>Comprehensive digital textbook with collaborative and powerful features, serving various teaching and learning needs effectively in one place.</p>
                              <p className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="800"><Link to={process.env.PUBLIC_URL + '/katbook'}>explore +</Link></p>
                        </div>                  
                      </div>                  
                    </Container>
                  </div>
                </div>
              </div>
          </section>
          <section class="section secFour SecMobAnimi">
            <div className='InSecMobAnimi'>
              <div className='UeHSecns UeHKampusSec'>
                    <div className='UeHSecImg'>
                      <img src={require('../../asstes/images/hKampusBgImg.jpg')} alt="UEducted" className='HSecBgs'/>
                      <div className='UeHBeforeImg kampusbefore'><img src={require('../../asstes/images/HKampusImg.webp')} alt="UEducted" data-aos="zoom-out" data-aos-duration="3000"/></div>
                    </div>
                    <div className='HSecConts'>
                      <Container>
                        <div className='HSContBox KampusTitles'>
                          <div className='HProTitles TitleLeftDiv'>
                                <h5 data-aos="fade-down" data-aos-duration="1000">The</h5>
                                <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Future</h2>
                                <h6 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">of Educational Institution<br/> Management Software</h6>
                                <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" className='HWebViewCont'>Embrace the Future of Education with efficiency,<br/> innovation, and seamless management. Say goodbye<br/> to paperwork hassles and focus on what truly matters:<br/> providing high-quality education.</p>
                                <p className='HMobViewCont'>Education's future requires efficient, innovative, well-managed institutions, which embrace features, ditch paperwork, prioritize quality education with comprehensive tools for adaptation.</p>
                          </div>     
                          <div className='HProTitles TitleRightDiv'> 
                                <h3 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600">KAMPUS</h3>
                                <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700" className='HWebViewCont'>KAMPUS integrates an Educational Institution Management<br/> Solution (EIMS), Teaching and Learning Management <br/>Solution (TLMS), and Campus Management Solution(CMS)<br/> that caters to the needs of  Administrators, Teachers, Students,<br/> and Parents. KAMPUS, offers Unique features such as Dynamic<br/> Timetable, a built-in CLOSED and OPEN communication platform, <br/>and attendance management for an efficient management of <br/>education. Experience the future today.</p>
                                <p className='HMobViewCont'>KAMPUS, An integrated platform for institutions, serves unique Features including dynamic timetable, In-built communication, attendance management, and streamlined management. </p>
                                <p className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="800"><Link to={process.env.PUBLIC_URL + '/kampus'}>explore +</Link></p>
                          </div>                  
                        </div>                  
                      </Container>
                    </div>
                  </div>  
              </div>  
          </section>
          <section class="section secFive SecMobAnimi">
              <div className='InSecMobAnimi'>
                <div className='UeHSecns UeHKSmaccSec'>
                  <div className='UeHSecImg'>
                      <img src={require('../../asstes/images/hKSmaccBgImg.jpg')} alt="UEducted" className='HSecBgs'/>
                      <div className='ksmaccbefore '>
                        <div className='MainmaccIcon'><img src={require('../../asstes/images/HKSmaccImg.webp')} alt="UEducted" data-aos="fade-up" data-aos-duration="3000"/></div>
                        <div className='maccIcon iconOne'><img src={require('../../asstes/images/HKSmaccIconOne.webp')} alt="UEducted" data-aos="fade-left" data-aos-duration="3000" data-aos-delay="200"/></div>
                        <div className='maccIcon icontwo'><img src={require('../../asstes/images/HKSmaccIconTwo.webp')} alt="UEducted" data-aos="fade-right" data-aos-duration="3000" data-aos-delay="400"/></div>
                        <div className='maccIcon iconThree'><img src={require('../../asstes/images/HKSmaccIconThree.webp')} alt="UEducted" data-aos="fade-right" data-aos-duration="3000" data-aos-delay="600"/></div>
                      </div>
                  </div>
                  <div className='HSecConts'>
                    <Container>
                      <div className='HSContBox KSmaccTitles'>
                        <div className='HProTitles TitleLeftDiv'>
                              <h5 data-aos="fade-down"  data-aos-duration="1000">The</h5>
                              <h2 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="100">Future</h2>
                              <h6 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="200">of Secured Closed<br/> Communication</h6>
                              <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300" className='HWebViewCont'>In the coming years, secured, closed, and <br/>intra-institutional communication will be vital. <br/>The integration of Exclusive CLOSED Communication <br/>channels, GPS monitoring, etc are factors that will<br/> ensure the future of CLOSED communication.</p>
                              <p className='HMobViewCont'>Secured closed communication gains importance in years ahead. Integration of exclusive closed channels, GPS ensures its future amid advancing communication technologies.</p>
                        </div>     
                        <div className='HProTitles TitleRightDiv'> 
                              <h3 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600">KSMACC</h3>
                              <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700" className='HWebViewCont'>KSMAAC, a first-of-its-kind, admin-managed<br/> INTRA-INSTITUTIONAL platform, offers CLOSED <br/>communication for students, parents, teachers,<br/> management, and other institution personnel. It <br/>provides end-to-end encrypted data and patented <br/>features in KATCHUP (messaging module) and <br/>KALL (calling module). Auto-generated Alumni Directory <br/>with CLOSED and OPEN Communication Channels. </p>
                              <p className='HMobViewCont'>Encrypted communication platform for institution members. Includes KATCHUP (messaging module) and KALL modules (calling module) with patented features for messaging and calling.</p>
                              <p className='exprbtn' data-aos="fade-down"  data-aos-duration="800" data-aos-delay="100"><Link to={process.env.PUBLIC_URL + '/ksmacc'}>explore +</Link></p>
                        </div>                  
                      </div>                  
                    </Container>
                  </div>
                </div>  
              </div>  
            </section>
            <section class="section secNine SecMobAnimi">
              <div className='InSecMobAnimi'>
              <div className='UeHSecns UeHUstudySec'>
                    <div className='UeHSecImg'>
                      <img src={require('../../asstes/images/hUStudyBgImg.webp')} alt="UEducted" className='HSecBgs'/>
                      <div className='UeHBeforeImg ustudybefore'><img src={require('../../asstes/images/hUStudyBgImgOne.webp')} alt="UEducted" data-aos="zoom-out" data-aos-duration="3000"/></div>
                    </div>
                    <div className='HSecConts'>
                      <Container>
                        <div className='HSContBox UstudyTitles'>
                          <div className='HProTitles TitleLeftDiv'>
                                <h5 data-aos="fade-down" data-aos-duration="1000">The</h5>
                                <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Future</h2>
                                <h6 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">of Uninterrupted<br/> Corporate Training </h6>
                                <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" className='HWebViewCont'>Corporate training bridges skill gaps enhances<br/> performance, and fosters a skilled workforce for<br/> adaptability, competitiveness, and sustainable<br/> growth amid industry changes and technological<br/> advancements. Empower Your Workforce!</p>
                                <p className='HMobViewCont'>Corporate training bridges skill gaps, boosts performance, adapts to industry changes, and fosters a skilled workforce amid technological advancements.</p>
                          </div>     
                          <div className='HProTitles TitleRightDiv'> 
                                <h3 data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="600">USTUDY </h3>
                                <p data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="700" className='HWebViewCont'>USTUDY is an online educational aggregator<br/> that offers flexible learning, overcoming <br/> geographical and time constraints, and accessible <br/> courses anytime, anywhere, for Corporates and  <br/>individuals. It also accompanies numerous benefits to <br/> both the organization and aspiring students. </p>
                                <p className='HMobViewCont'>USTUDY offers flexible online courses, breaking barriers of time and location for corporate learners, yielding advantages for organizations and individuals.</p>
                                <p className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><Link to={process.env.PUBLIC_URL + '/ustudy'}>explore +</Link></p>

                          </div>                  
                        </div>                  
                      </Container>
                    </div>
                  </div>  
              </div>  
            </section>
          </div>
          <section class="section secSix">
            <div className='UeHSecns HVerticalSec'>
              <div className='UeHSecImg'>
                  <img src={require('../../asstes/images/HVerticalBg.jpg')} alt="UEducted" className='HSecBgs'/>
              </div>
              <div className='HSecConts'>
                <Container>
                  <div className='HSVContBox'>
                    <div className='HSVCol HSVContTitle'>
                          <h2 data-aos="fade-down" data-aos-duration="1000">Verticals</h2>
                          <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200"> Our products cater to a diverse range of<br/> market segments and individuals, spanning<br/> across multiple verticals :</p>
                          <p className='exprbtn' data-aos="fade-down"  data-aos-duration="1000" data-aos-delay="300"><Link to={process.env.PUBLIC_URL + '/contactus'}>explore +</Link></p>
                    </div>     
                    <div className='HSVCol HSVLists'> 
                          <ul>
                            <li><Link to="/"><div className='vulicons'></div><strong>Schools</strong><small>Unlock educational excellence right from schools.</small></Link></li>
                            <li><Link to="/"><div className='vulicons'></div><strong>Tutorials</strong><small>Foster a culture of excellence and achievement.</small></Link></li>
                            <li><Link to="/"><div className='vulicons'></div><strong>Colleges / Universities</strong><small>Elevate your academy's impact<br/> by nurturing talents.</small></Link></li>
                            <li><Link to="/"><div className='vulicons'></div><strong>Academies</strong><small>Empowering future leaders for a competitive world.</small></Link></li>
                            <li><Link to="/"><div className='vulicons'></div><strong>Corporates</strong><small>Transform your corporate training at any point of time.</small></Link></li>
                          </ul>
                    </div>                  
                  </div>                  
                </Container>
              </div>
            </div>  
          </section>          
          <Testimonial/>
          <section class="section secEight">
            <div className='HNewsSec'>
              <Container>
                <Row>
                  <Col>
                  <div className='ComTitle'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">News & <br/>Insights</h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Stay informed, Stay Inspired:<br/> Explore the latest news and insights<br/> shaping the educational landscape<br/> with UEDUCATE.</p>
                    <p className='exprbtn' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300"><Link to="/">explore +</Link></p>
                  </div>
                  </Col>
                  <Col>
                    <div className='HNewsItems'>
                      <div className='HNewsItem'>
                        <div className='HNIImg'><img src={require ('../../asstes/images/hnewsimg-One.jpg')} alt="News & Insights"/></div>
                        <div className='HNICont'>
                          <h3>Lorem Ipsum has been the<br/> industry's standard </h3>
                          <p>Lorem Ipsum is simply dummy ext of the<br/> printing and typesetting industry. standard<br/> been the industry's standard dummy text as been<br/> the dustry's standard dummy text has</p>
                        </div>
                      </div>
                      <div className='HNewsItem'>
                        <div className='HNIImg'><img src={require ('../../asstes/images/hnewsimg-Two.jpg')} alt="News & Insights"/></div>
                        <div className='HNICont'>
                          <h3>Lorem Ipsum has been the<br/> industry's standard </h3>
                          <p>Lorem Ipsum is simply dummy ext of the<br/> printing and typesetting industry. standard<br/> been the industry's standard dummy text as been<br/> the dustry's standard dummy text has</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
     </div>
  )
}

export default Home